export default  function ColorPlatte() {
    return(
        {
            0:'#199719',
            1:'#19ab19',
            2:'#19cf19',
            3:'#21db19',
            4:'#33e219',
            5:'#4be819',
            6:'#49e819',
            7:'#62ed19',
            8:'#7cf119',
            9:'#96f719',
            10:'#aefb19',
            11:'#bffe19',
            12:'#c2fe19',
            13:'#dfff19',
            14:'#fff119',
            15:'#ffe019',
            16:'#ffcb19',
            17:'#ffb619',
            18:'#ff9519',
            19:'#ff0000',
        }
    )
}