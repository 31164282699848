import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {inject, observer} from "mobx-react";
import CardContainerDistrict from "../result-card/CardContainerDistrict";


function DistrictComments(props) {
    const {districtList} = props.seoStore;
    const {location} = props.routerStore;
    const {statsResults} = props.commonStore;

    const [values, setValues] = React.useState({
        displaySPCs : [],
    });

    useEffect(() => {
        (async () => {
            let district_code = districtList[`${location.pathname.substring(18)}`]['code'];
            let data = {};
            let spcintList = [];

            await fetch(`https://qs45puxbx7.execute-api.eu-west-1.amazonaws.com/default/dynamodb_district_spc_reader?district_code=${district_code}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'UtSMAo3HAb7oJ38p3utOH2gxsRYyez9m2gVzYR3b'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {


                     data.slice(0,2).forEach(function(item){
                         spcintList.push(Object.keys(item)[0]);
                    });
                    setValues({...values, displaySPCs: [...spcintList]})

                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            // obtain stats for selected three SPCs

            await fetch(`https://k0z0o11pg5.execute-api.eu-west-1.amazonaws.com/default/querySPCCard?factors=safety,employment&spcintlist=${spcintList.join()}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'aj7ooUNLkL3GtqQWhdop62IE0KlDnCJt8xE5I88J'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {

                    props.commonStore.changeState('statsResults', {...props.commonStore.statsResults, ...data})

                })
                .catch((error) => {
                    console.error('Error:', error);
                });


        })()
    }, []);


    return (
        <div >
            <Grid container>
                <Grid item>
                    <div style={{display:'flex', flexDirection:'column', margin:5}}>
                    {values.displaySPCs.map((spc, i)=>(statsResults[spc]!==undefined?
                        <CardContainerDistrict data = {statsResults[spc]} spc_int={parseInt(spc)} />: null
                    ))}
                    </div>
                </Grid>
            </Grid>

        </div>
    );
}

export default inject('commonStore', 'routerStore', 'seoStore') (observer(DistrictComments));