import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import LandingAppSearch from "./Landing_App_Search";
import CardContainerLanding from "../result-card/CardContainerLanding";


function LandingApp(props) {

    const {searchText, searchGeo, alertOn, statsResults} = props.commonStore;
    const iconMapping = {
        ThumbDown: "thumb_down",
        ThumbUp: "thumb_up",
        Comment: "comment",
    };

    const [values, setValues] = React.useState({
        latestReviews : [],
        address:[],
    });

    useEffect(() => {
        (async () => {

            let data = {};
            let spcintList = [];


            await fetch(`https://wf5ufwtkkh.execute-api.eu-west-1.amazonaws.com/default/ReviewLatest`)
                .then(response => response.json())
                .then(function(data){
                    spcintList = [...data]
                    setValues({...values, latestReviews: [...data]})
                })
                .catch(function(error){
                    console.log(error)
                });



            // obtain stats for selected three SPCs

            await fetch(`https://k0z0o11pg5.execute-api.eu-west-1.amazonaws.com/default/querySPCCard?factors=safety,employment&spcintlist=${spcintList.join()}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'aj7ooUNLkL3GtqQWhdop62IE0KlDnCJt8xE5I88J'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    props.commonStore.changeState('statsResults', {...props.commonStore.statsResults, ...data})

                })
                .catch((error) => {
                    console.error('Error:', error);
                });



        })()
    }, []);



    return (
        <div style={{ backgroundColor:'white', color:'grey'  }}>
            <div className="mdc-layout-grid">
                <div className="mdc-layout-grid__inner">
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12" style={{paddingTop:'10vh', paddingBottom:'8vh'}}>
                        <span className="mdc-typography--headline3" style={{color:'grey'}}>Find more before your move</span>
                    </div>

                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"  style={{display:'flex', flexDirection:'column'}}>
                        <span className="mdc-typography--body1">Tell us where you are interested</span>

                        <div  style={{display:'flex', flexDirection:'row', paddingTop:'20px', paddingBottom:'30px'}} >
                            <div   style={{border:'1px solid #e6e6e6', borderRadius:'30px', width: '60%', minWidth: '320px'}}>
                            <LandingAppSearch/>
                            </div>
                        </div>


                    </div>

                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"  style={{display:'flex', flexDirection:'column', width:'100%'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <span className="material-icons" style={{marginRight:'10px'}}>campaign</span>
                            <span className="mdc-typography--subtitle1">People are talking about</span>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', margin:5}}>
                            {values.latestReviews.map((spc, i)=>(statsResults[spc]!==undefined?
                                    <div style={{margin:'2px'}} key={i}>  <CardContainerLanding data = {statsResults[spc]} spc_int={parseInt(spc)}  /></div>: null
                            ))}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default inject('commonStore', 'routerStore') (observer(LandingApp));
