export default async function AppParams() {
    let tagList = {};
    let blogList = [];

    await fetch(`https://tps1knyvy7.execute-api.eu-west-1.amazonaws.com/default/getNeighbourhoodDescriptions_getway`)
        .then(response => response.json())
        .then(function(data){
            blogList = data.Blog_list;
        })
        .catch(error=> console.log(error));

    return {
        blogList: blogList,
    }
}