import React from 'react';
import {inject, observer} from "mobx-react";
import FlagContainerSearch from "./FlagContainerSearch";
import TagBox from "./TagBox";
import ScoreChart from "./ScoreChart";
import Paper from '@mui/material/Paper';

import "./cardcontainer.css";


function CardContainerDistrict(props) {

    const {ratings, defaultCenter, polygonCollection} = props.commonStore;


    const cardClick = (geo) => () => {

        window.open(`/searchstats?lat=${geo.lat}&lng=${geo.lng}&factors=safety,employment`)

    };


    return (
        <Paper className="cardcontainer-root" onClick={cardClick(props.data.geo)} elevation={3}>
            <div><FlagContainerSearch data={{flags: props["data"]["review"]["flags"], address: props["data"]["address"]}}
                                spc_int={props.spc_int}/>
            </div>
            <div><TagBox data={props["data"]["review"]["tags"].reduce((a, b) => {
                a.push(b['content']);
                return a
            }, [])} spcint={props.key}/></div>
            <div><ScoreChart data={props["data"]["stats"]} spcint={props.key}/></div>
        </Paper>
    );
}

export default inject('commonStore', 'routerStore', 'seoStore')(observer(CardContainerDistrict));
