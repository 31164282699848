import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";


function Menu(props) {
    const {location, push} = props.routerStore;

    const returnHome = () =>()=>{
        push('/home')
    };


    return (
        <div style={{height:'100vh',display:'flex', flexDirection:'column', justifyContent:'space-between',
            alignItems:'flex-start',
            backgroundColor:'white', maxWidth:'275px', minHeight:'100vh', position:'fixed' }}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                <img src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/SmartPostCode_Logo.png'}
                     alt="SmartPostCode Logo"
                     style={{cursor: (location.pathname==='/home'? 'auto': 'pointer'),
                         boxShadow: 'inset 0 0 10px 0px rgba(0,0,0,0.15)',
                         borderRadius: '50%',
                         height: '60px',
                         width: '60px',
                         marginTop:'15px',
                         marginBottom:40,
                     }}
                     onClick={()=>{window.location ="/home"}}
                />
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                    <a  href="/home"  style={{color: 'black', textDecoration: 'none', marginTop:'15px'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <span className="mdc-typography--subtitle1" style={{color:'#8cb47d', textShadow:'0px 0px 1px #A5A5A5'}} >Home</span>
                        </div>
                    </a>
                    <a  href="/searchstats?lat=51.5072178&lng=-0.1275862&factors=safety,employment"  style={{color: 'black', textDecoration: 'none', marginTop:'15px'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <span className="mdc-typography--subtitle1" style={{color:'#A5A5A5', textShadow:'0px 0px 1px #A5A5A5'}} >Area Search</span>
                        </div>
                    </a>
                    <a  href="/explore?commute0=51.5072178,-0.1275862,30,drive&factors=commuting,34,safety,34,employment,34"  style={{color: 'black', textDecoration: 'none',marginTop:'15px'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <span className="mdc-typography--subtitle1" style={{color:'#A5A5A5', textShadow:'0px 0px 1px #A5A5A5'}}>Area Explore <sup>beta</sup> </span>
                        </div>
                    </a>
                    <a  href="/district-profile/city-of-london"  style={{color: 'black', textDecoration: 'none', marginTop:'15px'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <span className="mdc-typography--subtitle1" style={{color:'#64b5f6', textShadow:'0px 0px 1px #A5A5A5'}} >District Profile</span>
                        </div>
                    </a>
                    <a  href="/blog"  style={{color: 'black', textDecoration: 'none',marginTop:'15px', }}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <span className="mdc-typography--subtitle1" style={{color:'#FFC000', textShadow:'0px 0px 1px #FFC000'}}>Blog</span>
                        </div>

                    </a>
                    <a  href="/about-us"  style={{color: 'black', textDecoration: 'none',marginTop:'15px'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <span className="mdc-typography--subtitle1" style={{color:'#ED7D31', textShadow:'0px 0px 1px #ED7D31'}}>About Us</span>
                        </div>
                    </a>
                </div>
            </div>

            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <span  className="mdc-typography--caption">©2018-23 All Rights Reserved Lepus Technology Limited.</span>
                <span className="mdc-typography--caption">-</span>
                <a href="https://s3-eu-west-1.amazonaws.com/lepustechnology-documentation/Terms_and_Conditions_v2.pdf"
                   target="_blank"
                   rel = "noopener noreferrer"
                   style={{color: 'black', textDecoration: 'none'}}
                >
                    <span  className="mdc-typography--caption">Terms & Conditions</span>
                </a>
                <span className="mdc-typography--caption">-</span>
                <span  className="mdc-typography--caption">We respect your privacy and only use strictly necessary cookies for basic website functionality </span>
            </div>
        </div>

    );
}

export default inject('commonStore', 'routerStore') (observer(Menu));