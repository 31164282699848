import { makeObservable, observable, computed, action } from "mobx"

class commonStore {

    displayOptions= {
        screenSize: 1280,
        resultsType: null,
        backendRunning: false,
        partialRunning: false,
        notification: false,
        tagFormOpen: false,
        searchWindowOpen: false,
        priorityOption: 'activeStep',
        sideOpen: true,
        sideOpen2: false,
        sideContent: null,
        sideContent2: null,
        areaCardOn: false,
        colorMap: false,
        tagByMap: false,
        queryByMap: false,
        countNeighbourhood:28000,
        countInput: 900,
        countSearch: 38000,
        reviewOpen: false,
    };

    promote = [];
    searchText = '';
    blogIndex = [];


    notificationBar = {
        cookie: {
            open: false,
            message: 'We only use cookies for necessary website functionality and do not use cookies for any target marketing activities ',
            variant: 'info'
        },
        notification: {
            open: false,
            message: '',
            variant: 'info',
        },
    };

    queryType = 'stats';
    defaultCenter ={lat: 51.513299, lng: -0.097695};
    defaultZoom=12;
    queryMarkers = [];
    polygonCollection = {};
    polygonPoints = [{lat: 51.513299, lng: -0.097695}];
    selectedSPCINT = null;

    tempAddress = '';  //display address in google search inputbox
    queryAddressSingle = {}; //temp dictionary containing spc_int, postcode, label. Created from google search inputbox address select action;
    statsAddressList =[]; // Multiple address contain spc_int, postcode, label
    exploreAddressList = []; //multiple address, contain spc_int, postcode, label, travel time, transportation.
    resultsAddressList = [];  // address list for users to choose neighbourhood for displaying
    colorMapList = [];
    colorResults = {points:{}, data:{}, scoreTable: {}};

    transportation='walk';
    time = 30;

    budgetOn = false;
    propertyType = ['House'];
    price = 0;
    searchPurpose = 'Rent';
    bedroomNumber = 2;

    selectedTag = [];
    tagList = {};

    reviewParams = {
        "write_flag":1,
        "Alt_Address": null,
        "Content": null,
        "Content_Type": "Tag",
        "Downvote": 0,
        "Latitude": null,
        "Latitude_Div": null,
        "Longitude": null,
        "Longitude_Div": null,
        "SPC_INT": null,
        "UID": null,
        "Upvote": 0,
        "Date": '1980-01-01'
    };

    get remainingTagList (){
        return this.selectedTag.reduce(function (a, b) {
            a = a.filter(tag => (b.toLowerCase()!==tag.toLowerCase() && 'InitialTags'.toLowerCase() !==tag.toLowerCase()));
            return a
        }, [...Object.keys(this.tagList)]);
    };

    basicFactorList ={
        name: ["Address", "SPC_INT",
            "Postcode_Center", "District Name","District Code",  'Lat_List', 'Lng_List', 'Latitude', 'Longitude'],
        type: ["string", "int",  "string",  "string", "string",  "list", "list", "float", "float"]
    };

     urlMap = {
        walk: 'Walk',
        public_transport: 'Publc_Transport',
        drive: 'Drive',
        commuting: {key:'Criterion_TravelTime', label:'Commuting',
            hint: 'Area outside commuting requirements will always be excluded', site:"GoogleMaps", unit: 'min',
            url: "https://maps.google.com/", activeStep: 100},
        safety: {key:'Criterion_Safety_Combined', label:'Safety',
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Violence, Property Damage, Criminal Damage and Other Minor. Data in period Nov 2018 - Oct 2019', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/', activeStep: 100},
        safety_crime_type_violence: {key:'Criterion_Safety_Violence', label:'Safety - Crime Type Violence',
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Violence, Sexual Offences, Robbery, and Possession of Weapons', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/', activeStep: 100},
        safety_crime_type_property_damage: {key:'Criterion_Safety_Property_Damage', label:'Safety - Crime Type Property Damage',
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Burglary and Vehicle crime', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/', activeStep: 100},
        safety_crime_type_criminal_damage: {key:'Criterion_Safety_Criminal_Damage', label:'Safety - Crime Type Criminal Damage',
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Criminal Damage and Arson', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/', activeStep: 100},
        safety_minor_crimes: {key:'Criterion_Safety_Minor', label:'Safety - Minor Crimes',
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Drugs, Anti-social Behaviour, Bicycle Theft, Public Order, Shoplifting, Theft from the Person, Other Theft, and Other Crime',
            site:"Police.UK", unit: '', url: 'https://www.police.uk/', activeStep: 100},
        employment: {key:'Criterion_Unemployment_Rate', label:'Employment',
            hint: '% of people who do not have a job among the working age population',
            site:"Nomis", unit: '%', url: 'https://www.nomisweb.co.uk/reports/lmp/la/contents.aspx', activeStep: 100},
        air_quality: {key:'Criterion_Air_Quality', label:'Air Quality',
            hint: 'A measure of air quality based on emissions rates for four pollutants: nitrogen dioxide, benzene, sulphur dioxide and particulates.\n' +
                'Each pollutant\'s atmospheric concentration was compared to a national standard value.',
            site:"UK Air", unit: '', url: 'https://uk-air.defra.gov.uk/', activeStep: 100},
        housing_conditions: {key:'Criterion_House_Conditions', label:'Housing Conditions',
            hint: '% of houses failed the Decent Homes Standard', site:"Nomis",
            unit: '%', url: 'https://www.nomisweb.co.uk/reports/localarea', activeStep: 100},
        household_overcrowding: {key:'Criterion_Household_Overcrowding', label:'Household Overcrowding',
            hint: '% of households that do not have sufficient space', site:"Nomis",
            unit: '%', url: 'https://www.nomisweb.co.uk/reports/localarea', activeStep: 100},
        property_price_median: {key:'Criterion_Property_Price_Median', label:'Property Price Median',
            hint: 'Median price for properties exchanged in period Apr 2018 - Mar 2019', site:"UK House Price Index",
            unit: '£', url: 'https://landregistry.data.gov.uk/app/ukhpi', activeStep: 100},
        property_price_growth_1_year: {key:'Criterion_Property_Price_Growth_1Y', label:'Property Price Growth - 1 Year',
            hint: 'Median price growth for properties exchanged in period Apr 2018 - Mar 2019 and Apr 2017 - Mar 2018', site:"UK House Price Index",
            unit: '%', url: 'https://landregistry.data.gov.uk/app/ukhpi', activeStep: 100},
        property_price_growth_3_year: {key:'Criterion_Property_Price_Growth_3Y', label:'Property Price Growth - 3 Year',
            hint: 'Median price growth for properties exchanged in period Apr 2018 - Mar 2019 and Apr 2015 - Mar 2016', site:"UK House Price Index",
            unit: '%', url: 'https://landregistry.data.gov.uk/app/ukhpi', activeStep: 100},
        property_price_growth_5_year: {key:'Criterion_Property_Price_Growth_5Y', label:'Property Price Growth - 5 Year',
            hint: 'Median price growth for properties exchanged in period Apr 2018 - Mar 2019 and Apr 2013 - Mar 2014', site:"UK House Price Index",
            unit: '%', url: 'https://landregistry.data.gov.uk/app/ukhpi', activeStep: 100},
        property_rent_affordability: {key:'Criterion_Property_Affordability_Rent', label:'Property Rent Affordability',
            hint: '% residents can afford private rent', site: 'BBC', unit: '%',
            url: 'https://www.bbc.co.uk/news/business-23234033', activeStep: 100},
        property_purchase_affordability: {key:'Criterion_Property_Affordability_Buy', label:'Property Purchase Affordability',
            hint: '% residents can afford property ownership', site: "BBC", unit: '%',
            url: "https://www.bbc.co.uk/news/business-23234033", activeStep: 100},
        distance_to_gp: {key:'Criterion_GP_Access', label:'Distance to GP',
            hint: 'average distance to closest GP', site: "NHS", unit: 'km',
            url: 'https://www.nhs.uk/Service-Search/GP/LocationSearch/4', activeStep: 100},
        young_people_area: {key:'Criterion_Age_Group_16_25', label:'Young People Area',
            unit: '%', hint: '% of population falls into the age group: 16-25', site: "BBC",
            url: 'https://www.bbc.co.uk/news/newsbeat-46815257', activeStep: 100},
        residents_between_26_39: {key:'Criterion_Age_Group_26_39', label:'Residents between 26-39',
            unit: '%', hint: '% of population falls into the age group: 26-39', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates', activeStep: 100},
        residents_between_40_59: {key:'Criterion_Age_Group_40_59', label:'Residents between 40-59',
            unit: '%', hint: '% of population falls into the age group: 40-59', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates', activeStep: 100},
        residents_over_60: {key:'Criterion_Age_Group_Over_60', label:'Residents over 60',
            unit: '%', hint: '% of population falls into the age group: Over 60', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates', activeStep: 100},
        dense_area: {key:'Criterion_Population_Density_High_Preferred', label:'Dense Area',
            unit: '/km2', hint: 'Population density. Higher score for area with higher density', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates', activeStep: 100},
        population_density_low_preferred: {key:'Criterion_Population_Density_Low_Preferred', label:'Population Density - Low Preferred',
            unit: '/km2', hint: 'Population density. Higher score for area with lower density', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates', activeStep: 100},
        restaurant_choice: {key:'Criterion_Restaurants_Density', label:'Restaurant Choice',
            unit: '/km2', hint: 'Only restaurants with food hygiene rating above 3 counted.', site: "Food Standard Agency",
            url: 'https://ratings.food.gov.uk/', activeStep: 100},
        search: 'Search_Purpouse',
        rent: 'Rent',
        sale: 'Sale',
        bedroom: 'Ideal_No_Of_Bed',
        type: 'Property_Type',
        budget: 'Max_Price',
        display: 'dataType',
        house: 'House',
        flat: 'Flat'
    };


    fullFactors = [
        {key:'Criterion_Air_Quality', label:'Air Quality', activeStep: 100,
            hint: 'A measure of air quality based on emissions rates for four pollutants: nitrogen dioxide, benzene, sulphur dioxide and particulates.\n' +
                'Each pollutant\'s atmospheric concentration was compared to a national standard value.',
            site:"UK Air", unit: '', url: 'https://uk-air.defra.gov.uk/'},
        {key:'Criterion_TravelTime', label:'Commuting', activeStep: 100,
            hint: 'Area outside commuting requirements will always be excluded', site:"GoogleMaps", unit: 'min',
            url: "https://maps.google.com/"},
        {key:'Criterion_GP_Access', label:'Distance to GP', activeStep: 100, unit: 'km',
            hint: 'average distance to closest GP', site: "NHS",
            url: 'https://www.nhs.uk/Service-Search/GP/LocationSearch/4'},
        {key:'Criterion_House_Conditions', label:'Housing Conditions', activeStep: 100,
            hint: '% of houses failed the Decent Homes Standard', site:"Nomis",
            unit: '%', url: 'https://www.nomisweb.co.uk/reports/localarea'},
        {key:'Criterion_Household_Overcrowding', label:'Household Overcrowding', activeStep: 100,
            hint: '% of households that do not have sufficient space', site:"Nomis",
            unit: '%', url: 'https://www.nomisweb.co.uk/reports/localarea'},
        {key:'Criterion_Population_Density_High_Preferred', label:'Dense Area', activeStep: 100, unit: '/km2',
            hint: 'Population density. Higher score for area with higher density', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates'},
        {key:'Criterion_Population_Density_Low_Preferred', label:'Population Density - Low Preferred', activeStep: 100, unit: '/km2',
            hint: 'Population density. Higher score for area with lower density', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates'},
        {key:'Criterion_Property_Affordability_Buy', label:'Property Purchase Affordability', activeStep: 100,
            hint: '% residents can afford property ownership', site: "BBC", unit: '%',
            url: 'https://www.bbc.co.uk/news/business-23234033'},
        {key:'Criterion_Property_Affordability_Rent', label:'Property Rent Affordability', activeStep: 100,
            hint: '% residents can afford the private rent', site:"BBC", unit: '%',
            url: 'https://www.bbc.co.uk/news/business-23234033'},
        {key:'Criterion_Property_Price_Growth_1Y', label:'Property Price Growth - 1 Year', activeStep: 100,
            hint: 'Median price growth for properties exchanged in period Apr 2018 - Mar 2019 and Apr 2017 - Mar 2018', site:"UK House Price Index",
            unit: '%', url: 'https://landregistry.data.gov.uk/app/ukhpi'},
        {key:'Criterion_Property_Price_Growth_3Y', label:'Property Price Growth - 3 Year', activeStep: 100,
            hint: 'Median price growth for properties exchanged in period Apr 2018 - Mar 2019 and Apr 2015 - Mar 2016', site:"UK House Price Index",
            unit: '%', url: 'https://landregistry.data.gov.uk/app/ukhpi'},
        {key:'Criterion_Property_Price_Growth_5Y', label:'Property Price Growth - 5 Year', activeStep: 100,
            hint: 'Median price growth for properties exchanged in period Apr 2018 - Mar 2019 and Apr 2013 - Mar 2014', site:"UK House Price Index",
            unit: '%', url: 'https://landregistry.data.gov.uk/app/ukhpi'},
        {key:'Criterion_Property_Price_Median', label:'Property Price Median', activeStep: 100,
            hint: 'Median price for properties exchanged in period Apr 2018 - Mar 2019', site:"UK House Price Index",
            unit: '£', url: 'https://landregistry.data.gov.uk/app/ukhpi'},
        {key:'Criterion_Age_Group_16_25', label:'Young People Area', activeStep: 100, unit: '%',
            hint: '% of population falls into the age group: 16-25', site: "BBC",
            url: 'https://www.bbc.co.uk/news/newsbeat-46815257'},
        {key:'Criterion_Age_Group_26_39', label:'Residents between 26-39', activeStep: 100, unit: '%',
            hint: '% of population falls into the age group: 26-39', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates'},
        {key:'Criterion_Age_Group_40_59', label:'Residents between 40-59', activeStep: 100, unit: '%',
            hint: '% of population falls into the age group: 40-59', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates'},
        {key:'Criterion_Age_Group_Over_60', label:'Residents over 60', activeStep: 100, unit: '%',
            hint: '% of population falls into the age group: Over 60', site: "ONS",
            url: 'https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates'},
        {key:'Criterion_Restaurants_Density', label:'Restaurant Choice', activeStep: 100, unit: '/km2',
            hint: 'Only restaurants with food hygiene rating above 3 counted.', site: "Food Standard Agency",
            url: 'https://ratings.food.gov.uk/'},
        {key:'Criterion_Safety_Combined', label:'Safety', activeStep: 100,
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Violence, Property Damage, Criminal Damage and Other Minor. Data in period Nov 2018 - Oct 2019', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/'},
        {key:'Criterion_Safety_Criminal_Damage', label:'Safety - Crime Type Criminal Damage', activeStep: 100,
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Criminal Damage and Arson', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/'},
        {key:'Criterion_Safety_Minor', label:'Safety - Minor Crimes', activeStep: 100,
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Drugs, Anti-social Behaviour, Bicycle Theft, Public Order, Shoplifting, Theft from the Person, Other Theft, and Other Crime',
            site:"Police.UK", unit: '', url: 'https://www.police.uk/'},
        {key:'Criterion_Safety_Property_Damage', label:'Safety - Crime Type Property Damage', activeStep: 100,
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Burglary and Vehicle crime', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/'},
        {key:'Criterion_Safety_Violence', label:'Safety - Crime Type Violence', activeStep: 100,
            hint: 'Recorded crime number per 1,000 population in period Nov 2018 - Oct 2019: Violence, Sexual Offences, Robbery, and Possession of Weapons', site:"Police.UK", unit: '',
            url: 'https://www.police.uk/'},
        {key:'Criterion_Unemployment_Rate', label:'Employment', activeStep: 100,
            hint: '% of people who do not have a job among the working age population',
            site:"Nomis", unit: '%', url: 'https://www.nomisweb.co.uk/reports/lmp/la/contents.aspx'}
    ];

    selectedFactors = [
        {key:'Criterion_TravelTime', label:'Commuting', activeStep: 100, score: 33.3,
            hint: 'Area outside commuting requirements will always be excluded', site:"GoogleMaps", unit: 'min',
            url: "https://maps.google.com/"},
        {key:'Criterion_Safety_Combined', label:'Safety', activeStep: 100,score: 33.3,
            hint: 'Sum of scores in four categories: Violence, Property Damage, Criminal Damage and Other Minor', site:"Police.UK", unit: '%',
            url: 'https://www.police.uk/'},
        {key:'Criterion_Unemployment_Rate', label:'Employment', activeStep: 100,score: 33.3,
            hint: '% of people who do not have a job among the working age population',
            site:"Nomis", unit: '%', url: 'https://www.nomisweb.co.uk/reports/lmp/la/contents.aspx'}
    ];

    get statsFullFactors(){
        return this.fullFactors.filter(x=> x.key !=='Criterion_TravelTime')
    }

    get statsSelectedFactors (){
        return this.selectedFactors.filter(x=> x.key !=='Criterion_TravelTime')
    };

    get statsRemainingFactors (){
        let selectedKeys = this.statsSelectedFactors.reduce(function (a, b) {
            a.push(b.key);
            return a
        }, []);
        return this.statsFullFactors.filter(x => !selectedKeys.includes(x.key));
    };

    get districtFullFactors (){
        return this.statsFullFactors;
    };
    get districtSelectedFactors(){
        return this.statsSelectedFactors;
    };
    get districtRemainingFactors (){
        return this.statsRemainingFactors;
    }

    get exploreFullFactors (){
        return this.fullFactors
    };

    get exploreSelectedFactors (){
        return this.selectedFactors
    }

    get exploreRemainingFactors(){
        let selectedKeys = this.exploreSelectedFactors.reduce(function (a, b) {
            a.push(b.key);
            return a
        }, []);
        return this.exploreFullFactors.filter(x => !selectedKeys.includes(x.key));
    };


    get scoreSum (){
        return  this.exploreSelectedFactors.map(a => a.score).reduce((a, b) => a + b, 0);
    }

    reCalFactors (target){
        let newList = [...this.selectedFactors];
        if(target ==='activeStep'){
            let max = Math.max.apply(Math,newList.map(function(o){return o.score;}));
            newList.forEach(function(factor){
                factor.activeStep = Math.round(100* factor.score/max)
            });
        } else {
            let sum = newList.map(a => a.activeStep).reduce((a, b) => a + b, 0)
            newList.forEach(function(factor){
                factor.score = Math.round(1000* factor.activeStep/sum)/10
            })
        }
        this.selectedFactors = [...newList];
    }

    get statsRouterOptions (){
        let address = (this.statsAddressList.length>0? [...this.statsAddressList]: (Object.keys(this.queryAddressSingle).length>0? [this.queryAddressSingle]:[] ))
        let latlng = (address.length ===0? []: address.reduce(function (a, b) {a.push(b.lat, b.lng); return a}, []) );

        let factors =  this.statsSelectedFactors.map(a => a.label).reduce(function(a, b) {
            a.push(b.toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_'));
            return a
        },[]);

        let options = {
            query: this.queryType,
            latlng: latlng.join(),
            display: this.displayOptions.dataType,
            factors: factors.join()
        };
        let string = '';
        for (let [key, value] of Object.entries(options)) {
            if (value){string = `${string}&${key}=${value}`}
        }
        return string.slice(1)
    }

    get exploreOptionalParams (){
        let params_api = {
            Preset:'None',
            basic_criteria_names: this.basicFactorList.name,
            basic_criteria_types: this.basicFactorList.type
        };
        let budget = {};

        if (this.budgetOn){
            budget = {
                Ideal_No_Of_Bed:this.bedroomNumber,
                Search_Purpouse:this.searchPurpose,
                Property_Type:this.propertyType.join(),
                Max_Price:this.price
            }
        }

        let factors = this.exploreSelectedFactors.reduce(function(a, b){
            a[b.key]=b.score;
            return a
        },{});

        return {...params_api, ...factors, ...budget}
    }

    get exploreRouterOptions (){
        let factors = this.exploreSelectedFactors.reduce(function (a,b) {
            a.push(b.label.toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_'), b.score);
            return a
        }, []);

        let budget = (this.budgetOn?  {
            search: this.searchPurpose.toLowerCase(),
            bedroom: this.bedroomNumber,
            type: this.propertyType.reduce(function (a, b) {
                a.push(b.toLowerCase());
                return a;
            },[]).join(),
            budget: this.price,
        }: {});

        let address = (this.exploreAddressList.length>0? [...this.exploreAddressList]: (Object.keys(this.queryAddressSingle).length>0? [{...this.queryAddressSingle,  time: this.time, transportation:this.transportation} ]:[] ))
        let addressURL =  (address.length ===0? {}: address.reduce(function(a, b, i) {
            a[`commute${i}`] = `${b.lat},${b.lng},${b.time},${b.transportation}`;
            return a
        },{}));

        let options ={ query: this.queryType,  ...addressURL, factors: factors.join(), ...budget};
        let string = '';
        for (let [key, value] of Object.entries(options)) {
            if (value){string = `${string}&${key}=${value}`}
        }
        return string.slice(1)
    }

    statsResults = {};  // neighbourhood data for displaying

    exploreResults = {}; // neighbourhood data for displaying

    get districtResults (){
        return this.statsResults
    }


    get selectedResult () {
        return (
            this[`${this.displayOptions.resultsType}Results`][this.selectedSPCINT]?
                this[`${this.displayOptions.resultsType}Results`][this.selectedSPCINT]:
                (this.colorResults[this.selectedSPCINT]? this.colorResults[this.selectedSPCINT]: {})
        );
    }; //selected neighbourhood data

    reviews = {};
    comments = {};
    flags = {
        template:{love: {label: 'love here', count: 0, uidList:[]}, moveIn: {label: `let's move here`, count: 0, uidList:[]},
            moveOut: {label:'moving away', count:0, uidList:[]}, hate: {label: 'stay away',count:0, uidList:[]}},
        points: {},
        flags: {}
    };

    get inputRank (){
        let flags = {...this.flags.flags};
        let reviews = {...this.reviews.tags};

        let flagsConverted = Object.keys(flags).reduce(function (a, b) {
            a[b] = {address:flags[b]['address'], lat:flags[b]['Latitude'], lng:flags[b]['Longitude'],  score: flags[b]['score'], count:flags[b]['hate']['count'] + flags[b]['love']['count']+flags[b]['moveIn']['count']+ flags[b]['moveOut']['count']};
            return a;
        }, {});
        let combinedInput = Object.keys(reviews).reduce(function(a, b){
            if (a[b]){
                a[b]['count'] = a[b]['count'] + reviews[b]['Others'].length + reviews[b]['Whole_Area'].length
            } else {
                a[b] = {
                    address:(reviews[b]['Whole_Area'].length>0? reviews[b]['Whole_Area'][0]['Address']:
                            (reviews[b]['Others'].length>0? reviews[b]['Others'][0]['Address'] : null )
                    ),
                    lat:(reviews[b]['Whole_Area'].length>0? reviews[b]['Whole_Area'][0]['Latitude']:
                            (reviews[b]['Others'].length>0? reviews[b]['Others'][0]['Latitude'] : null )
                    ),
                    lng:(reviews[b]['Whole_Area'].length>0? reviews[b]['Whole_Area'][0]['Longitude']:
                            (reviews[b]['Others'].length>0? reviews[b]['Others'][0]['Longitude'] : null )
                    ),
                    score:0,
                    count: reviews[b]['Others'].length + reviews[b]['Whole_Area'].length
                };
            }
            return a;
        }, flagsConverted);
        let items = Object.keys(combinedInput).map(function (key) {
            return [key, combinedInput[key]['score'], combinedInput[key]['count'],  combinedInput[key]['address'], combinedInput[key]['lat'], combinedInput[key]['lng'] ]
        });
        let UpvoteRankList = items.filter(item => item[1] > 0).sort(function(first, second) {
            return second[1] - first[1];
        });

        let DownvoteRankList = items.filter(item => item[1] < 0).sort(function(first, second) {
            return  first[1] - second[1];
        });

        let countRankList =items.filter(item => item[2] !== 0).sort(function(first, second) {
            return second[2] - first[2];
        });

        return {
            upVoted: UpvoteRankList,
            downVoted: DownvoteRankList,
            mostDiscussed:countRankList
        };
    }

    ratings = {template: [
            {content: 'Safety', rating: 0, count: 0, uidList: []},
            {content: 'Cleanness', rating: 0, count: 0, uidList: []},
            {content: 'Convenience', rating: 0, count: 0, uidList: []},
            {content: 'Schools', rating: 0, count: 0, uidList: []},
            {content: 'Street View', rating: 0, count: 0, uidList: []},
            {content: 'Community Spirit', rating: 0, count: 0, uidList: []}
        ]};

    get selectedReview (){
        let selectedReviews = [];
        if(this.reviews.tags[`${this.selectedSPCINT}`]){
            selectedReviews = [
                ...this.reviews.tags[`${this.selectedSPCINT}`]['Whole_Area'],
                ...this.reviews.tags[`${this.selectedSPCINT}`]['Others']
            ]
        };

        selectedReviews.sort(function (a, b) {
            return b.Upvote - a.Upvote
        });
        return selectedReviews
    }


    addFactor (key, FromList, ToList){
        const updated = this[FromList].filter(factor => factor.key === key);
        this[ToList]= [...this[ToList],...updated]
    }

    removeFactor (key, listName){
        const updated = this[listName].filter(factor=> factor.key !== key);
        this[listName] =[...updated];
    };

    addItem(item, listName, autoKey){
        if (autoKey) {
            this[listName].push({...item, key: this[listName].length})
        } else {
            this[listName].push(item);
        }
    }

    changeStateDic (value, key, dicName){
        this[dicName][key]=value;
    }

    changeState(name, val){
        this[name]=val;
    }


    constructor(value) {
        makeObservable(this, {
            displayOptions: observable,
            promote: observable,
            searchText: observable,
            blogIndex: observable,
            notificationBar: observable,
            queryType: observable,
            defaultCenter: observable,
            defaultZoom: observable,
            queryMarkers: observable,
            polygonCollection: observable,
            polygonPoints: observable,
            colorMapList: observable,

            selectedSPCINT: observable,

            transportation: observable,
            time: observable,

            tempAddress: observable,
            queryAddressSingle: observable,
            statsAddressList: observable,
            exploreAddressList: observable,

            tagList: observable,
            selectedTag: observable,
            reviewParams: observable,
            urlMap: observable,

            fullFactors: observable,
            selectedFactors: observable,


            budgetOn: observable,
            propertyType: observable,
            price: observable,
            searchPurpose: observable,
            bedroomNumber: observable,

            statsResults: observable,
            exploreResults: observable,
            resultsAddressList: observable,

            reviews: observable,
            comments: observable,
            ratings: observable,
            flags: observable,

            districtResults: computed,
            inputRank: computed,

            statsFullFactors: computed,
            statsRemainingFactors: computed,
            statsSelectedFactors: computed,

            exploreFullFactors: computed,
            exploreRemainingFactors: computed,
            exploreSelectedFactors: computed,

            districtFullFactors: computed,
            districtRemainingFactors: computed,
            districtSelectedFactors: computed,

            scoreSum: computed,

            statsRouterOptions: computed,
            exploreRouterOptions: computed,

            exploreOptionalParams: computed,
            selectedResult: computed,
            selectedReview: computed,
            remainingTagList: computed,

            addItem: action,
            addFactor: action,
            removeFactor: action,
            reCalFactors: action,

            changeStateDic: action,
            changeState: action
        });
        this.value = value
    }
}



export default new commonStore();
