import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import BlogNav from "./Blog_Nav";
import BlogPage from './Blog_Page';
import './blog.css';
import AppParams from "../../functions/AppParams";


function Blog(props) {

    const {location, push} = props.routerStore;
    const {blogIndex} = props.commonStore;


    useEffect(() => {
        (async () => {
            if(blogIndex.length===0) {
                let appSetting = await AppParams();
                props.commonStore.changeState('blogIndex', appSetting.blogList);
            }
        })()
    }, []);

    return (
        <div className="blog-root" >
            <div className="blog-main">

                {(location.pathname==='/blog'? <BlogNav/>: <BlogPage/>)}

            </div>
        </div>
    );
}

export default inject('commonStore', 'routerStore') (observer(Blog));