import {IconButton, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {useCookies, withCookies} from "react-cookie";
import {ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined} from "@material-ui/icons";
import React from "react";
import "./tagboxwithvote.css"


function TagBoxwithVote(props) {
    const {reviews, statsResults, selectedSPCINT} = props.commonStore;
    const [cookies, setCookie] = useCookies();

    const onClick = (item, voteType) =>() =>{
        (async () => {
            if (cookies[item.uid]==='create'){
                let newValue = {
                    open: true,
                    message: `Please do not vote your own tag`,
                    variant: 'warning',
                };
                props.commonStore.changeStateDic(newValue, 'notification', 'notificationBar' );
            } else {
                let params = [];
                let cookieValue = null;
                if ((cookies[item.uid] ==='1' && voteType ==='Upvote') || (cookies[item.uid]==='-1' && voteType==='Downvote')){
                    params = [{type: voteType, increment: -1 }];
                    cookieValue = '0'
                } else if (cookies[item.uid] ==='1' && voteType ==='Downvote'){
                    params = [{type: 'Downvote', increment: 1 }, {type: 'Upvote', increment: -1 }];
                    cookieValue = '-1'
                } else if (cookies[item.uid] ==='-1' && voteType ==='Upvote'){
                    params = [{type: 'Downvote', increment: -1 }, {type: 'Upvote', increment: 1 }];
                    cookieValue = '1'
                } else if (cookies[item.uid] ==='0' || cookies[item.uid]===undefined ){
                    params = [{type: voteType, increment: 1 }];
                    if (voteType ==='Downvote'){ cookieValue = '-1'} else {cookieValue = '1'}
                }

                for await (let param of params){
                    let data = {};
                    await fetch(`https://ru9bd696y8.execute-api.eu-west-1.amazonaws.com/default/dynamodb_writer_js?key=${item.uid}&$vote_type=${param.type}&increment=${param.increment}`, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key':'U9VH4xLINz8YPHx0lgIE51quNWHmJbNT5Eo2bTw4'
                        },
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then(() => {
                            let reviewCategory = 'Whole_Area';
                            let findIndex = reviews[selectedSPCINT]['tags'][reviewCategory].findIndex(x => x.uid === item.uid);
                            let newItem = {...reviews[selectedSPCINT]['tags'][reviewCategory][findIndex]};
                            newItem[param.type.toLowerCase()] = newItem[param.type.toLowerCase()] + param.increment;
                            let newReviewCategoryItems =[...reviews[selectedSPCINT]['tags'][reviewCategory]];
                            newReviewCategoryItems[findIndex] = {...newItem};
                            let newReviewSPCINTTags = {
                                ...reviews[selectedSPCINT]['tags'],
                                [reviewCategory]: newReviewCategoryItems,
                            };
                            let newReview = {
                                ...reviews[selectedSPCINT],
                                tags: newReviewSPCINTTags,
                            };
                            props.commonStore.changeStateDic(newReview, selectedSPCINT, 'reviews');


                            let findIndex2 = statsResults[selectedSPCINT]['review']['tags'].findIndex(x => x.uid === item.uid);
                            let newStatsTagsItems =[...statsResults[selectedSPCINT]['review']['tags']];
                            newStatsTagsItems[findIndex2] = {...newItem};


                            let newStatsSPCINTTags = {
                                ...statsResults[selectedSPCINT]['review'],
                                'tags': newStatsTagsItems,
                            };
                            let newStats = {
                                ...statsResults[selectedSPCINT],
                                'review': newStatsSPCINTTags,
                            };

                            props.commonStore.changeStateDic(newStats, selectedSPCINT, 'statsResults');
                            setCookie(item.uid, cookieValue, { maxAge: 365 * 24 * 60 * 60, sameSite: 'strict'})
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }
            }

        })()
    };


    return (
        <div
            className="tagbox-tag"
            key={props.key}

        >
            <Typography variant="caption">{props.item.content}</Typography>
            <IconButton
                aria-label="like"
                onClick = {onClick(props.item, 'Upvote')}
                color={(cookies[props.item.uid]==='1'? 'primary': 'default')}
                size="small"
            >
                {(cookies[props.item.uid]==='1'? <ThumbUp fontSize="inherit" />: <ThumbUpOutlined fontSize="inherit" />)}
            </IconButton>
            <Typography variant="caption">{props.item.upvote - props.item.downvote}</Typography>

            <IconButton
                aria-label="dislike"
                onClick = {onClick(props.item, 'Downvote')}
                color={(cookies[props.item.uid]==='-1'? 'primary': 'default')}
                size="small"
            >
                {(cookies[props.item.uid]==='-1'? <ThumbDown fontSize="inherit" />: <ThumbDownOutlined fontSize="inherit" />)}
            </IconButton>


        </div>
    )


}

export default inject('commonStore', 'routerStore') (observer(withCookies(TagBoxwithVote)));