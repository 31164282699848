import React from "react";
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import "./scorechart.css"
import {inject, observer} from "mobx-react";

function ScoreChart (props){

    const {fullFactors, statsSelectedFactors } = props.commonStore;

    const factorMap =  fullFactors.reduce(function (a, b, i) {
            a[b['key']]  = b['label']
            return a;
        }, {})

    const valuetext = (value) => {
        return `${value}`;
    }

    const IOSSlider = styled(Slider)(({ theme }) => ({
        color: '#3880ff',
        height: 2,
        padding: '15px 0',
        '& .MuiSlider-thumb': {
            height: 8,
            width: 8,
            backgroundColor: '#bfbfbf',
            border: '1px solid #bfbfbf',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            fontSize: 12,
            fontWeight: 'normal',
            top: 5,
            backgroundColor: 'unset',
            color: '#696969',
        },
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-rail': {
            opacity: 0.5,
            backgroundColor: '#bfbfbf',
        },
        '& .MuiSlider-mark': {
            backgroundColor: '#bfbfbf',
            height: 8,
            width: 1,
        },
        '& .MuiSlider-markLabel': {
            fontSize:"10px",
            overflowWrap:"break-word",
        },
    }));

    return (
        <div className="scorechart-root">
            {statsSelectedFactors.map((factor, k)=>(
                <div key={k} className="scorechart-container" >
                    <div className="scorechart-label">
                        <span>{factor['label']}</span>
                    </div>

                    <div className="scorechart-slider">
                        <IOSSlider
                            disabled
                            getAriaLabel={() => 'Score'}
                            orientation="horizontal"
                            getAriaValueText={valuetext}
                            valueLabelDisplay="on"
                            marks={ [
                                {value: 0, label: '',},
                                {value: Math.round(props.data[`${factor['key'].slice(10)}_District`]), label: 'District Avg',},
                                {value: 100, label: '',},
                            ]}
                            defaultValue={ Math.round(props.data[factor['key'].slice(10)])}

                        />
                    </div>

                </div>
            ))}
        </div>
    )
}


export default inject('commonStore', 'routerStore', 'seoStore') (observer(ScoreChart));