import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import MapContainerSearch from "../map/MapContainerSearch";
import SearchBox from "./SearchBox";
import SearchResult from "./SearchResult";
import AreaCard from "../area-card/AreaCard";
import SearchFactors from "./SearchFactors";
import Notification from "../Notification";
import "./searchresultcontainer.css";

function SearchResultContainer(props) {

    const {displayOptions, defaultCenter, polygonPoints} = props.commonStore;


    return (
        <div className="search-result-root">

            <div className="search-result-main">

                    < div className="search-result-searchbox">{displayOptions.backendRunning?<img
                        src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                        alt="thank-you"
                        style={{display: 'block', margin: 'auto', width: '20px'}}
                    /> : <SearchBox/> } </div>

                 <div className="search-result-resultlist"><SearchResult/></div>


            </div>

            <div className="search-result-body">

                <div style={{display:'flex', flexDirection:'column', zIndex:2, position:'absolute'}}>
                    <div className="search-result-factors"><SearchFactors/> </div>
                    {(displayOptions.areaCardOn ? <div className="search-result-area"><AreaCard/></div>:null)}

                </div>

                <div className="search-result-map">
                    <MapContainerSearch center={defaultCenter} points={polygonPoints}/>
                </div>

            </div>
            <Notification/>


        </div>

    )

}


export default inject('commonStore', 'routerStore') (observer(SearchResultContainer));


/*



      */



