import React from 'react';
import {Grid} from '@material-ui/core';
import {inject, observer} from "mobx-react";


function RatingScore(props) {


    return (

           <div>
               <Grid container direction='column'>
                   {props.data.map((rating)=>(
                       <Grid container  alignItems="center" key={rating.content} style={{paddingBottom:'10px'}}>
                           <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                               <span style={{color:'grey', fontSize:'12px', fontWeight:'bold'}}>
                                   {rating.content}
                               </span>
                           </Grid>
                           <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                               <div style={{display:'flex', flexDirection:'row'}}>
                               <span style={{color:'grey', fontSize:'12px', paddingRight:'10px', fontWeight:'bold'}}>
                                   {(Math.round(rating.rating*10)/10).toFixed(1)}
                               </span>
                               <div style={{display:'flex', flexDirection:'row'}}>
                                   {[1,2,3,4,5].map((j)=>(
                                       <div key={j}>
                                           {( j <= rating.rating? <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star</span>:
                                               (j - rating.rating < 1?  <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star_half</span> :
                                                   <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star_outline</span>))}
                                       </div>
                                   ))}
                               </div>
                               </div>
                           </Grid>
                           <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                               <span style={{color:'grey', fontSize:'12px'}}>
                                  {rating.count} Rated
                               </span>
                           </Grid>
                       </Grid>
                   ))}
               </Grid>
            </div>

    );
}

export default inject('commonStore', 'routerStore') (observer(RatingScore));