import React from 'react';
import {inject} from "mobx-react";


function BlogNav(props) {

    const {blogIndex} = props.commonStore;
    const {location, push} = props.routerStore;

    const [values, setValues] = React.useState({
        len: [10,5],
        filterText: '',
        order:'date',
        view:'card',
    });


    const renderBlogList = (blogList) =>{
        let blogListSorted =(values.filterText==='' ?[...blogList]:
                [...blogList].filter(x=>x.label.toLowerCase().includes(values.filterText.toLowerCase()))
        );

        if (values.order==='alphabet'){
            blogListSorted.sort(function (first, second) {
                return first['label'].localeCompare(second['label'])
            })
        }
        return blogListSorted

    };

    const onClick = (i, category, url) => () => {
        window.location = url;
        props.commonStore.changeState('blogSelected', [i, category])

    };

    const onClickLen = (i, add) => () =>{
        let newLen = [...values.len];
        newLen[i] = newLen[i] + add;
        setValues({...values, len: newLen })
    };



    return (
        <div style={{backgroundColor:'white', color:'grey', width:'100%'  }} >
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', padding:'15px'}}>
                <label className="mdc-text-field mdc-text-field--with-trailing-icon">
                    <span className="mdc-text-field__ripple"></span>
                    <input className="mdc-text-field__input" type="text" aria-labelledby="my-label-id" onChange={(event)=>{setValues({...values, filterText:event.target.value})}}/>
                    <span className="material-icons">search</span>
                    <span className="mdc-line-ripple"></span>
                </label>
                <div>|</div>
                {(values.order==='date'?  <button className="mdc-button" onClick={()=>{setValues({...values,order: 'alphabet' })}}>
                    <div className="mdc-button__ripple"></div>
                    <span className="mdc-button__label">Sort by Alphabet</span>
                </button>:   <button className="mdc-button" onClick={()=>{setValues({...values,order: 'date' })}}>
                    <div className="mdc-button__ripple"></div>
                    <span className="mdc-button__label">Sort by Date</span>
                </button> )}
                <div>|</div>
                {(values.view==='card'? <button className="mdc-button" onClick={()=>{setValues({...values,view: 'list' })}}>
                        <div className="mdc-button__ripple"></div>
                        <span className="mdc-button__label">List View</span>
                    </button> :
                    <button className="mdc-button" onClick={()=>{setValues({...values,view: 'card' })}}>
                        <div className="mdc-button__ripple"></div>
                        <span className="mdc-button__label">Card View</span>
                    </button>  )}

            </div>

            <div style={{width:'100%'}}>
                {(blogIndex?   blogIndex.slice(0,2).map((category, i) =>(<div style={{padding:'10px'}} key={i}>
                        <span key={i} className="mdc-typography--headline6">{category['category']}</span>
                        <div style={{display:'flex',
                            flexDirection:`${(values.view==='card'? 'row': 'column')}`,
                            flexWrap:'wrap',
                            alignItems:'flex-start',
                            justifyContent:'flex-start',
                        }}>
                            {renderBlogList(category['blogList']).slice(0,values.len[i]).map((blog, j)=>(<div key={j} onClick={onClick( i, category['category'] ,blog.url)}>
                                    {(values.view==='card'?<div className="mdc-card" style={{width:'150px', marginRight:'10px', marginBottom:'10px'}}>
                                        <div className="mdc-card__primary-action">
                                            <div className="mdc-card__media mdc-card__media--square"
                                                 style={{backgroundImage: `url(${blog.img})`, height:'150px', width:'150px'}}>
                                            </div>

                                        </div>
                                        <div className="mdc-card__actions" style={{display:'flex', flexDirection:'column', textAlign:'center'}}>
                                            <span className="mdc-typography--body2"> {blog['label']}</span>
                                            <span className="mdc-typography--caption"> {blog['date']} </span>
                                        </div>
                                    </div>:  <a key={i}  href={`${blog.url}`}  style={{  textDecoration: 'none'}}>
                                        <li className="mdc-list-item" style={{ color:' #202020', width:'65vw' }}>
                                            <span className="mdc-list-item__ripple"></span>
                                            <div className="mdc-layout-grid__inner" key={j} style={{ color:' #202020', width:'65vw' }}>
                                                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6>"><span className="mdc-typography--subtitle2" >{blog.label}</span></div>
                                                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"><span className="mdc-typography--caption" >{blog.date}</span></div>
                                                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"><span className="mdc-typography--caption" >{blog.subcategory}</span></div>

                                            </div>
                                        </li>

                                    </a> )}

                                </div>
                            ))}
                            <div style={{marginRight:'10px', marginBottom:'10px'}}  className="mdc-fab" onClick={onClickLen(i, 5)}>
                                <div className="mdc-fab__ripple"></div>
                                <span className="mdc-fab__icon material-icons">add</span><span>5</span>
                            </div>

                        </div> </div> ))
                    : null)}



            </div>


        </div>
    );
}

export default inject('commonStore', 'routerStore') (BlogNav);




