import React from 'react';
import {inject, observer} from "mobx-react";
import Chip from '@mui/material/Chip';


function SearchFactors(props) {

    const {fullFactors, selectedFactors, statsResults} = props.commonStore;

    const displayFactors = ["Criterion_Safety_Combined",  "Criterion_Unemployment_Rate", "Criterion_Age_Group_16_25", "Criterion_Restaurants_Density", "Criterion_Population_Density_High_Preferred" ];

    const selectedFactorsKeys = selectedFactors.reduce(function(a,b,i){
        a= [...a, b['key']]
        return a;
    }, [])


    const factorMap =  fullFactors.reduce(function (a, b, i) {
        a[b['key']]  = b['label'];
        return a;
    }, {});


    const handleClick = (factor) =>() => {
        (async () => {

            props.commonStore.changeStateDic(true, 'backendRunning', 'displayOptions')

            let data = {}
            let newSelectedFactors = []
            if (selectedFactorsKeys.includes(factor)) {
                newSelectedFactors = selectedFactors.filter(x => x.key !== factor)
            } else {
                newSelectedFactors = [...selectedFactors, ...fullFactors.filter(x => x.key === factor)]
            }

            // generate url

            let factorString = newSelectedFactors.reduce(function(a, b, i){
                a.push(b['label'].toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_'))
                return a;
            }, []).join()


            // update stats for SPCs

            await fetch(`https://k0z0o11pg5.execute-api.eu-west-1.amazonaws.com/default/querySPCCard?factors=${factorString}&spcintlist=${Object.keys(statsResults).join()}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'aj7ooUNLkL3GtqQWhdop62IE0KlDnCJt8xE5I88J'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {

                    props.commonStore.changeState('statsResults', {...statsResults, ...data})

                })
                .catch((error) => {
                    console.error('Error:', error);
                });


            props.commonStore.changeState('selectedFactors', newSelectedFactors)
            props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions')

        })()
    };


    return (
        <div className="">
            {displayFactors.map((factor, i)=>(
                    <Chip key={i} label={factorMap[factor]}
                          color={(selectedFactorsKeys.includes(factor)? "primary": "default")}
                          style={{
                              backgroundColor:(selectedFactorsKeys.includes(factor)? "#2196f3": "white"),
                              margin: "5px",
                              fontWeight: "bold",
                              border: "1px solid #2196f3"
                          }}   
                          onClick={handleClick(factor)} />
            ))}
        </div>
    )

}


export default inject('commonStore', 'routerStore') (observer(SearchFactors));




