import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import CardContainerSearch from "../result-card/CardContainerSearch";
import Paper from '@mui/material/Paper';
import ColorPlatte from "../map/ColorPlatte";
import Button from "@mui/material/Button";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import MobileStepper from "@mui/material/MobileStepper/MobileStepper";
import {useTheme} from "@mui/material";


function SearchResultMobile(props) {
    const {statsResults, selectedSPCINT, polygonCollection} = props.commonStore;
    const {location} = props.routerStore;
    const theme = useTheme();
    const [values, setValues] = React.useState({
        querySPCINT: null,
        rank: 0
    });


    useEffect(() => {
        (async () => {
            try {
            props.commonStore.changeStateDic(false, 'sideOpen', 'displayOptions')
            props.commonStore.changeStateDic(true, 'backendRunning', 'displayOptions')
            let data = {};
            let spcint = null;
            let spcintList = [];
            let center = {};
            let polygonPoints = [];
            let selectedFactors = [];

            props.commonStore.changeState('queryMarkers',
                [{lat: parseFloat(location.search.split('&')[0].split('=')[1]),
                    lng: parseFloat(location.search.split('&')[1].split('=')[1])
                }])

            // obtain the spcint data
            await fetch(`https://fh1tg5kmk6.execute-api.eu-west-1.amazonaws.com/default/queryLatLngCard${location.search}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'cYXSCNwtVQa9lCKzAnpHL82qtwhHDENP5dgsvhf4'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data!=='Invalid Address'){
                        spcint = data.params.selectedSPCINT
                        center = {lat: data.params.lat, lng: data.params.lng}
                        selectedFactors = [...data.params.factors]
                        props.commonStore.changeStateDic(data[spcint], spcint, 'statsResults');
                        setValues({...values, querySPCINT: spcint})
                        props.commonStore.changeState('selectedSPCINT', spcint);
                        props.commonStore.changeState('defaultCenter', {lat: data.params.lat, lng: data.params.lng});
                        props.commonStore.changeState('selectedFactors', selectedFactors);
                    } else {
                        let newValue = {
                            open: true,
                            message: 'Invalid Address. Support England Only',
                            variant: 'warning',
                        };
                        props.commonStore.changeStateDic(newValue, 'notification', 'notificationBar' );
                    }

                })
                .catch((error) => {
                    let newValue = {
                        open: true,
                        message: 'Error',
                        variant: 'error',
                    };
                    props.commonStore.changeStateDic(newValue, 'notification', 'notificationBar' );
                });

            // obtain the spcint polygon
            await fetch(`https://3d1ktapzj0.execute-api.eu-west-1.amazonaws.com/default/spcPolygon?spcint=${spcint}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'cguuxnQQ8EkHXIS2C1cM5yM6IWx1xUW5S5uc2od7'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {

                    props.commonStore.changeStateDic(data[spcint]['polygon'], spcint, 'polygonCollection');
                    polygonPoints = [...polygonPoints, ...data[spcint]['polygon']]
                    props.commonStore.changeState('polygonPoints',polygonPoints)

                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            // obtain surrounding ratings and comments

            await fetch(`https://x8f118p1dc.execute-api.eu-west-1.amazonaws.com/default/reviewLatLngArea${location.search}&r=0.25`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'4Q1BvfHoNv5SFEts5yFh56XErMI9oF2N2AYPm2qI'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    props.commonStore.changeState('reviews', {...props.commonStore.reviews, ...data});
                    let spcintListFull = Object.keys(data).sort((a,b)=> data[b]['totalCount'] - data[a]['totalCount']);
                    spcintList = spcintListFull.slice(0,3)
                    polygonPoints = Object.keys(data).reduce((a, b)=>{
                        if (data[b].lat){
                            a.push({lat: data[b].lat, lng: data[b].lng});
                        }


                        if (data[b]['tags']['Others'].length!==0){
                            for (let item of data[b]['tags']['Others']) {
                                a.push({lat: item.lat, lng: item.lng})
                            }
                        }
                        return a;
                    }, [...polygonPoints])


                    props.commonStore.changeState('polygonPoints',polygonPoints)

                })
                .catch((error) => {
                    console.error('Error:', error);
                });


            // obtain stats for selected three SPCs

            await fetch(`https://k0z0o11pg5.execute-api.eu-west-1.amazonaws.com/default/querySPCCard${location.search}&spcintlist=${spcintList.join()}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'aj7ooUNLkL3GtqQWhdop62IE0KlDnCJt8xE5I88J'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {

                    props.commonStore.changeState('statsResults', {...props.commonStore.statsResults, ...data})

                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions')}catch(e){
                    props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions')
                }

        })()
    }, []);


    const handleStep=(step) => () => {
        (async () => {
            let data = {}
            let newRank = Object.keys(statsResults).indexOf(`${selectedSPCINT}`) + step
            let newSPCINT = parseInt(Object.keys(statsResults)[newRank])

            if (polygonCollection[newSPCINT]===undefined){

                // obtain the spcint polygon
                await fetch(`https://3d1ktapzj0.execute-api.eu-west-1.amazonaws.com/default/spcPolygon?spcint=${newSPCINT}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key':'cguuxnQQ8EkHXIS2C1cM5yM6IWx1xUW5S5uc2od7'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {

                        props.commonStore.changeStateDic(data[newSPCINT]['polygon'], newSPCINT, 'polygonCollection');
                        props.commonStore.changeState('polygonPoints',data[newSPCINT]['polygon'])

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            } else {
                props.commonStore.changeState('polygonPoints',polygonCollection[newSPCINT])
            }

            props.commonStore.changeState('selectedSPCINT',  newSPCINT)



        })()
    };






    return (
        <div>
            <div >

                    {Object.keys(statsResults).filter(spc=> spc===`${selectedSPCINT}`).map((spc, i)=>(
                        <Paper elevation={parseInt(spc)===selectedSPCINT?24:0}  style={{ backgroundColor: parseInt(spc)===selectedSPCINT?  ColorPlatte()[Math.min(20-Math.ceil(statsResults[selectedSPCINT][`sum`]/5),19)]: null, border:'1px white solid'}} key={i}>
                            <CardContainerSearch data = {statsResults[spc]} spc_int={parseInt(spc)} />
                        </Paper>
                        ))}


                <MobileStepper
                    variant="text"
                    steps={Object.keys(statsResults).length}
                    position="static"
                    activeStep={Object.keys(statsResults).indexOf(`${selectedSPCINT}`)}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleStep(1)}
                            disabled={Object.keys(statsResults).indexOf(`${selectedSPCINT}`) +1 === Object.keys(statsResults).length }
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleStep(-1)} disabled={Object.keys(statsResults).indexOf(`${selectedSPCINT}`) === 0}


                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />

            </div>
        </div>
    );
}

export default inject('commonStore', 'routerStore', 'seoStore') (observer(SearchResultMobile));