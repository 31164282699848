import React from 'react';
import Grid from '@mui/material/Grid';
import './about-us.css';


function AboutUs() {

    return (
        <div className="about-us-root" >

            <Grid container  >

                    <Grid container item xs={11} md={11}  className="mdc-card" style={{color:'grey', padding:'20px', margin:'10px'}}>
                        <Grid item xs={12} md={12}>
                            <span className="mdc-typography--subtitle2">We are two young passionate people who enjoy dynamic lifestyle and keep moving homes every one or two years. </span>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <span className="mdc-typography--subtitle2">We started the project because we wish similar tool had existed on the internet.</span>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <span className="mdc-typography--subtitle2">It was fully developed by the two of us with the intention to create something useful. And we will continue to keep it free for the public usage. </span>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={12}  style={{margin:'10px'}} >
                        <Grid item xs={12} md={5}  className="mdc-card" style={{marginRight:'10px', marginBottom: '10px'}}>
                         <div className="mdc-layout-grid">
                            <div className="mdc-layout-grid__inner">
                                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                                    <div className="mdc-layout-grid__inner" >
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12" >
                                            <img
                                                src="https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/avatar-woman-color.png"
                                                alt="Lisha Tan"
                                                style={{height:'90px', width:'90px'}}
                                            />
                                        </div>
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                                            <a className="mdc-button"  href="http://www.linkedin.com/in/lisha-tan" target="_blank" rel="noopener noreferrer">
                                                <div className="mdc-button__ripple"></div>
                                                <img
                                                    src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/LinkedIn.png'}
                                                    alt="LinkedIn"
                                                    style={{height:'20px'}}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                                    <div className="mdc-layout-grid__inner">
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                            <span className="mdc-typography--caption">Co-Founder / CEO<br/></span>
                                            <span className="mdc-typography--headline6">Lisha Tan</span>
                                        </div>
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                            <span className="mdc-typography--body2">9 years in data analytics and reporting. <br/> </span>
                                            <span className="mdc-typography--body2">Studied marketing and decided to do something totally different after years of exploring career interests.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Grid>
                        <Grid item xs={12} md={5} className="mdc-card" style={{marginRight:'10px', marginBottom: '10px'}}>
                         <div className="mdc-layout-grid">
                            <div className="mdc-layout-grid__inner">
                                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
                                    <div className="mdc-layout-grid__inner" >
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12" >
                                            <img
                                                src="https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/avatar-man-color.png"
                                                alt="Pawel Ladosz"
                                                style={{height:'90px', width:'90px'}}
                                            />
                                        </div>
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                                            <a className="mdc-button" target="_blank" href="https://scholar.google.com/citations?user=fSEWVN8AAAAJ&hl=en" rel="noopener noreferrer">
                                                <div className="mdc-button__ripple"></div>
                                                <img
                                                    src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/Google_Scholar.png'}
                                                    alt="Google Scholar"
                                                    style={{height:'20px'}}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-9">
                                    <div className="mdc-layout-grid__inner">
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                            <span className="mdc-typography--caption">Co-Founder / CTO<br/></span>
                                            <span className="mdc-typography--headline6">Pawel Ladosz</span>
                                        </div>
                                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                            <span className="mdc-typography--body2">A researcher in robotics and Lifelong learning. <br/> </span>
                                            <span className="mdc-typography--body2">Want to see the day humans build a colony on the Mars.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Grid>

                    </Grid>

            </Grid>

        </div>
    );
}

export default AboutUs;