import React from 'react';
import PropTypes from 'prop-types';
import {CheckCircle, Close, Error, Info, Warning} from '@material-ui/icons';
import {amber, green} from '@material-ui/core/colors';
import {Button, IconButton, Snackbar, SnackbarContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {inject, observer} from "mobx-react";

const variantIcon = {
    success: CheckCircle,
    warning: Warning,
    error: Error,
    info: Info,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={`${classes[variant]}`}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`}/>
                    {message}
        </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <Close className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};


const useStyles2 = makeStyles(theme => ({
    info: {
        backgroundColor: '#4caf50',
        width: '60vw',
    },
}));

function MySnackbarCookieWrapper(props) {
    const classes = useStyles2();
    const { className, message, onClose, variant, ...other } = props;

    return (
        <SnackbarContent
            className={`${classes[variant]}`}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar">
                    {message}
                </span>
            }
            action={[
                <Button key="cookies" aria-label="cookies" color="inherit" href="http://www.bbc.co.uk/webwise/guides/about-cookies" target="_blank" rel = "noopener noreferrer">
                    What are Cookies
                </Button>,
                <Button key="close" aria-label="close" color="inherit" onClick={onClose}>
                    Agree
                </Button>,
            ]}
            {...other}
        />
    );
}

MySnackbarCookieWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};




function Notification(props) {

    const {displayOptions,  notificationBar} = props.commonStore;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        let newValue = {...notificationBar.notification};
        newValue['open'] = false;
        props.commonStore.changeStateDic(newValue, 'notification', 'notificationBar');
    };

    const cookieAccept = () => {
        let newValue = {...notificationBar.cookie};
        newValue['open'] = false;
        props.commonStore.changeStateDic(newValue, 'cookie', 'notificationBar');
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={notificationBar.cookie.open}
            >
                <MySnackbarCookieWrapper
                    onClose={cookieAccept}
                    variant={notificationBar.cookie.variant}
                    message={notificationBar.cookie.message}
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: (displayOptions.screenSize>800? 'bottom': 'top'),
                    horizontal: 'center',
                }}
                open={notificationBar.notification.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant={notificationBar.notification.variant}
                    message={notificationBar.notification.message}
                />
            </Snackbar>
        </div>
    );
}

export default inject('commonStore', 'routerStore') (observer(Notification));