import React from 'react';
import {inject, observer} from "mobx-react";
import FlagContainerSearch from "./FlagContainerSearch";
import TagBox from "./TagBox";
import ScoreChart from "./ScoreChart";
import Paper from '@mui/material/Paper';

import "./cardcontainer.css";


function CardContainerSearch(props) {

    const {ratings, defaultCenter, polygonCollection, displayOptions} = props.commonStore;


    const cardClick = (spc_int) => () => {
        (async () => {

            props.commonStore.changeStateDic(false, 'areaCardOn', 'displayOptions')
            props.commonStore.changeState('selectedSPCINT', spc_int);
            props.commonStore.changeStateDic(true, 'backendRunning', 'displayOptions');

            let data = {};
            if (!polygonCollection[spc_int]) {
                await fetch(`https://3d1ktapzj0.execute-api.eu-west-1.amazonaws.com/default/spcPolygon?spcint=${spc_int}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'cguuxnQQ8EkHXIS2C1cM5yM6IWx1xUW5S5uc2od7'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {

                        props.commonStore.changeStateDic(data[spc_int]['polygon'], spc_int, 'polygonCollection');


                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }

            if (!ratings[spc_int]) {
                await fetch(`https://c2z58t00pa.execute-api.eu-west-1.amazonaws.com/default/spcRatings?spc_int=${spc_int}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': '59z5Nyzxdg535sGxSR1to5I4qrKCUBcK1ETBwnp7'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.length === 0) {
                            props.commonStore.changeStateDic(ratings['template'], spc_int, 'ratings');
                        } else {
                            props.commonStore.changeStateDic(data, spc_int, 'ratings');
                        }

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }

            props.commonStore.changeState('polygonPoints', [{...defaultCenter}, ...polygonCollection[spc_int]])
            props.commonStore.changeStateDic(true, 'areaCardOn', 'displayOptions')
            props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions')

        })()


    };


    return (
        <Paper className="cardcontainer-root" onClick={cardClick(props.spc_int)} elevation={3}>
            <div><FlagContainerSearch data={{flags: props["data"]["review"]["flags"], address: props["data"]["address"]}}
                                spc_int={props.spc_int}/>
            </div>
            <div><TagBox data={props["data"]["review"]["tags"].reduce((a, b) => {
                a.push(b['content']);
                return a
            }, [])} spcint={props.key}/></div>
            {displayOptions.screenSize>=1280? <div><ScoreChart data={props["data"]["stats"]} spcint={props.key}/></div>: null}

        </Paper>
    );
}

export default inject('commonStore', 'routerStore', 'seoStore')(observer(CardContainerSearch));
