import React from 'react';
import {Typography} from '@material-ui/core';
import {inject, observer} from "mobx-react";




function FlagContainerExplore(props) {


    return (
        <div  >
            <div >
                <div style={{ marginBottom: '10px'}}>
                    <span  variant="body2" style={{color:'grey', fontSize:'14px', fontWeight:'bold'}}>{(props.data["address"]? `Recommended Area ${props.rank}: `: ``)}</span>
                    <span  variant="body2" style={{color:'grey', fontSize:'14px'}}>{(props.data["address"]? `Around ${props.data.address}`: ``)}</span>

                </div>
                <div style={{display:'flex', flexDirection:'row', marginBottom: '5px'}}>

                    <Typography variant="body2" style={{color:'grey', fontSize:'14px', paddingRight:'10px', fontWeight:'bold'}}>
                        {(props.data['flags']["count"]!==0? `${(props.data.flags.totalScore/props.data.flags.count).toFixed(1)}`: ``)}
                    </Typography>
                    <div style={{display:'flex', flexDirection:'row', paddingRight:'10px'}}>
                        {[1,2,3,4,5].map((j)=>(
                            <div key={j}>
                                {(j <= props.data.flags.totalScore/props.data.flags.count?
                                    <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star</span>:
                                    (j - props.data.flags.totalScore/props.data.flags.count < 1?
                                        <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star_half</span> :
                                        <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star_outline</span>))}
                            </div>))}
                    </div>

                    <Typography variant="body2" gutterBottom align="left" style={{color:'grey', fontSize:'14px', display:'flex', flexDirection:'row'}}>
                        {props.data.flags.count}
                    </Typography>
                    <Typography variant="body2" gutterBottom align="left" style={{color:'grey', fontSize:'14px', display:'flex', flexDirection:'row'}}>
                        &nbsp;Rated
                    </Typography>

                </div>
            </div>
        </div>




    );
}

export default inject('commonStore', 'routerStore') (observer(FlagContainerExplore));