import React, {Component} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {Grid, IconButton, Input, InputAdornment, MenuItem} from '@material-ui/core';
import {Clear, Menu, Search, ChevronLeft, ArrowBackIos} from '@material-ui/icons';
import {inject, observer} from "mobx-react";


const searchOptions = {
    componentRestrictions: {
        country: 'gb'
    },
    types: []
};

class SearchBoxMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '' ,
            message: '',
        };
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            address: this.props.commonStore.tempAddress

        })
    };

    handleChange = address => {
        this.setState({ address });
        this.props.commonStore.changeState('tempAddress', address);

    };

    onCancel = ()=>()=>{
        this.setState({address: ''});
        this.props.commonStore.changeState('tempAddress', '')
    };

    onCollapse = ()=> ()=>{
        this.props.commonStore.changeStateDic(!this.props.commonStore.displayOptions.sideOpen2, 'sideOpen2', 'displayOptions')
    }


    onSearch = ()=> ()=>{
        let factorString = this.props.commonStore.statsSelectedFactors.reduce(function(a, b, i){
            a = [...a, b['label'].toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_')]
            return a;
        }, []).join()

        if(this.props.commonStore.searchGeo.lat===0 || this.props.commonStore.searchGeo.lng===0 ){
            this.props.commonStore.changeState('alertOn', true);
        }else {
            this.props.commonStore.changeState([{lat: this.props.commonStore.searchGeo.lat, lng: this.props.commonStore.searchGeo.lng}, ...this.props.commonStore.queryMarkers], 'queryMarkers')
            window.open(`/searchstats?lat=${this.props.commonStore.searchGeo.lat}&lng=${this.props.commonStore.searchGeo.lng}&factors=${factorString}`, '_self')
        }
    };

    handleSelect = address => {
        this.setState({ address });
        this.props.commonStore.changeState('tempAddress', address);

        let factorString = this.props.commonStore.statsSelectedFactors.reduce(function(a, b, i){
            a = [...a, b['label'].toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_')]
            return a;
        }, []).join()

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng =>
                {
                    this.props.commonStore.changeState('searchGeo', {lat:latLng.lat, lng: latLng.lng  } );
                    this.props.commonStore.changeState('searchText', address);
                    this.props.commonStore.changeState([{lat: this.props.commonStore.searchGeo.lat, lng: this.props.commonStore.searchGeo.lng}, ...this.props.commonStore.queryMarkers], 'queryMarkers')
                    window.open(`/searchstats?lat=${this.props.commonStore.searchGeo.lat}&lng=${this.props.commonStore.searchGeo.lng}&factors=${factorString}`, '_self')
                }
            )
            .catch(error => {
                console.error(error);

            });
    };



    onError = (status, clearSuggestions) => {
        clearSuggestions();
    };



    render(){

        return (

            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onError={this.onError}
                onSelect={this.handleSelect}
                searchOptions = {searchOptions}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div >
                        <Grid container alignItems="center" justifyContent="center" style={{minHeight: '48px'}}>

                            <Input
                                {...getInputProps({
                                    placeholder: 'England Only ...',
                                })}
                                disableUnderline={true}
                                style={{width:'85vw'}}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="delete"  style={{color:'dimGray'}} onClick ={this.onSearch()}>
                                            {this.props.commonStore.displayOptions.backendRunning?<img
                                                src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                                                alt="loading"
                                                style={{display: 'block', margin: 'auto', width: '20px',}}
                                            />: <Search />}
                                        </IconButton>
                                        <div style={{ color:"lightGrey", fontSize:"20px", height:"30px", display:'flex', alignItems:'center'}}>|</div>
                                        <IconButton aria-label="delete" style={{color:'dimGray'}} onClick ={this.onCollapse()}>
                                            {this.props.commonStore.displayOptions.backendRunning?<img
                                                src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                                                alt="loading"
                                                style={{display: 'block', margin: 'auto', width: '20px',}}
                                            />: <ArrowBackIos />}
                                        </IconButton>

                                    </InputAdornment>
                                }
                            />

                        </Grid>

                        <div
                            className="autocomplete-dropdown-container"
                            style={{position:'absolute',
                                zIndex:100,
                                display: 'flex',
                                flexDirection:'column',
                                backgroundColor:'white',
                                margin:'5px',

                            }}
                        >
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <MenuItem
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </MenuItem>
                                );
                            })}
                        </div>

                    </div>
                )}
            </PlacesAutocomplete>

        )
    }
}

export default inject('commonStore', 'routerStore') (observer(SearchBoxMobile));