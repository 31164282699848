import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import ExploreResult from "./ExploreResult";
import ExploreBox from "./ExploreBox";
import ExploreFactors from "./ExploreFactors";
import MapContainerExplore from "../map/MapContainerExplore";
import AreaCard from "../area-card/AreaCard";
import "./exploreresultcontainer.css";
import Notification from "../Notification";
import Grow from '@mui/material/Grow';

function ExploreResultContainer(props) {

    const {displayOptions, defaultCenter, polygonPoints} = props.commonStore;


    const [values, setValues] = React.useState({
        checked: false,
    });


    useEffect(() => {
        (async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setValues({...values, checked: true})

        })()
    }, []);



    return (
        <div className="explore-result-root">

            <div className="explore-result-main">
                <Grow
                    in={values.checked}
                    mountOnEnter unmountOnExit
                >
                < div className="explore-result-searchbox">  <ExploreBox/></div>
                </Grow>
                <div className="explore-result-resultlist"> <ExploreResult/></div>
            </div>

            <div className="explore-result-body">

                <div style={{display:'flex', flexDirection:'column', zIndex:2, position:'absolute'}}>
                    <div className="explore-result-factors"> <ExploreFactors/> </div>
                    {(displayOptions.areaCardOn ? <div className="explore-result-area"><AreaCard/></div>:null)}

                </div>

                <div className="explore-result-map">
                    <MapContainerExplore center={defaultCenter} points={polygonPoints}/>
                </div>

            </div>

            <Notification/>


        </div>

    )

}


export default inject('commonStore', 'routerStore') (observer(ExploreResultContainer));


/*



      */



