import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import {useTheme} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardContainerExplore from "../result-card/CardContainerExplore";
import ColorPlatte from "../map/ColorPlatte";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import { KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons';



function ExploreResultMobile(props) {
    const {urlMap, exploreResults, statsResults, selectedSPCINT, polygonCollection} = props.commonStore;
    const {location} = props.routerStore;
    const theme = useTheme();
    const [values, setValues] = React.useState({
        querySPCINT: null,
        spcintList: [],
        rank: 0
    });



    useEffect(() => {
        (async () => {
            try {
            props.commonStore.changeStateDic(false, 'sideOpen', 'displayOptions')
            props.commonStore.changeStateDic(true, 'backendRunning', 'displayOptions')
            let data = '';
            let postcode = '';
            let spcintList = [];
            let selectedFactors = {};
            let polygonPoints = [];
            let params = {};
            let url = '';
            let exploreCriteria = location.search.slice(1).split('&').reduce(function (a,b) {
                a[b.split('=')[0]] = b.split('=')[1].split(',')
                return a;
            },{})

            props.commonStore.changeState('defaultCenter', {lat: parseFloat(exploreCriteria['commute0'][0]), lng: parseFloat(exploreCriteria['commute0'][1])});
            props.commonStore.changeState('queryMarkers', [{lat: parseFloat(exploreCriteria['commute0'][0]), lng: parseFloat(exploreCriteria['commute0'][1])}]);

            // obtain the postcode data

            await fetch(`https://api.postcodes.io/postcodes?lon=${exploreCriteria['commute0'][1]}&lat=${exploreCriteria['commute0'][0]}`)
                .then(res => {
                    return res.json()
                })
                .then(data => {
                    if (data.result.length >0 ){
                        postcode = data.result[0]['postcode'].replace(/\s/g, '')
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            // url generation

            params['commute0'] = [postcode,
                Math.max(15, parseInt(exploreCriteria['commute0'][2])/2),
                Math.max(15, parseInt(exploreCriteria['commute0'][2])),
                urlMap[exploreCriteria['commute0'][3]]
            ].join()

            params = exploreCriteria['factors'].reduce(function (a,b,i) {
                if (i%2 ===0){
                    a[urlMap[b]['key']] = exploreCriteria['factors'][i+1]
                    selectedFactors[b]={...urlMap[b], score: exploreCriteria['factors'][i+1]}
                }
                return a;
            },{...params})


            url = Object.keys(params).reduce(function (a, b, i) {
                a.push(`${b}=${params[b]}`)
                return a;
            }, []).join('&')


            props.commonStore.changeState('selectedFactors', Object.keys(selectedFactors).reduce(function (a,b,i) {
                a.push(selectedFactors[b]);
                return a;
            },[]));



            // obtain explore response
            await fetch(`https://i9154967ye.execute-api.eu-west-1.amazonaws.com/default/smartpostcode-api-ready-finder?${url}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'5DPKhCWsQu7dE6xSmrYQZ87jpzaTE7nM6dJQVn7T'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {

                    if (data['message']===undefined){
                        if (Object.keys(data).length !==0){

                        spcintList = Object.keys(data).sort((a,b)=> data[a]['Overall_Score']['Rank'] - data[b]['Overall_Score']['Rank'])
                        setValues({...values, spcintList: spcintList})
                        props.commonStore.changeState('exploreResults', data);
                        props.commonStore.changeState('selectedSPCINT', parseInt(spcintList[0]));

                        } else {

                            let newValue = {
                                open: true,
                                message: 'No results found',
                                variant: 'warning',
                            };
                            props.commonStore.changeStateDic(newValue, 'notification', 'notificationBar' );
                        }
                    }

                })
                .catch((error) => {
                    let newValue = {
                        open: true,
                        message: 'Error',
                        variant: 'error',
                    };
                    props.commonStore.changeStateDic(newValue, 'notification', 'notificationBar' );
                });

            // obtain stats for recommended SPCs

            await fetch(`https://k0z0o11pg5.execute-api.eu-west-1.amazonaws.com/default/querySPCCard?factors=${Object.keys(selectedFactors).filter(key=> key !=="commuting")}&spcintlist=${spcintList.join()}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'aj7ooUNLkL3GtqQWhdop62IE0KlDnCJt8xE5I88J'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    props.commonStore.changeState('statsResults', {...props.commonStore.statsResults, ...data})
                })
                .catch((error) => {
                    console.error('Error:', error);
                });


            // obtain the spcint polygon
            await fetch(`https://3d1ktapzj0.execute-api.eu-west-1.amazonaws.com/default/spcPolygon?spcint=${spcintList[0]}`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'cguuxnQQ8EkHXIS2C1cM5yM6IWx1xUW5S5uc2od7'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {

                    props.commonStore.changeStateDic(data[spcintList[0]]['polygon'], spcintList[0], 'polygonCollection');
                    polygonPoints = [...polygonPoints, ...data[spcintList[0]]['polygon']]
                    props.commonStore.changeState('polygonPoints',polygonPoints)

                })
                .catch((error) => {
                    console.error('Error:', error);
                });


            // obtain surrounding ratings and comments

            await fetch(`https://x8f118p1dc.execute-api.eu-west-1.amazonaws.com/default/reviewLatLngArea?lat=${exploreCriteria['commute0'][0]}&lng=${exploreCriteria['commute0'][1]}&r=0.25`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key':'4Q1BvfHoNv5SFEts5yFh56XErMI9oF2N2AYPm2qI'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    props.commonStore.changeState('reviews', {...props.commonStore.reviews, ...data});
                    polygonPoints = Object.keys(data).reduce((a, b)=>{
                        if (data[b].lat){
                            a.push({lat: data[b].lat, lng: data[b].lng});
                        }
                        if (data[b]['tags']['Others'].length!==0){
                            for (let item of data[b]['tags']['Others']) {
                                a.push({lat: item.lat, lng: item.lng})
                            }
                        }
                        return a;
                    }, [...polygonPoints])

                    props.commonStore.changeState('polygonPoints',polygonPoints)

                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions')} catch(e){
                props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions')
            }

        })()
    }, []);



    const handleStep=(step) => () => {
        (async () => {
            let data = {}
            let newRank = values.rank + step
            let newSPCINT = values.spcintList[newRank]

            setValues({
                ...values,
                rank : newRank
            })

            if (polygonCollection[newSPCINT]===undefined){

                // obtain the spcint polygon
                await fetch(`https://3d1ktapzj0.execute-api.eu-west-1.amazonaws.com/default/spcPolygon?spcint=${newSPCINT}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key':'cguuxnQQ8EkHXIS2C1cM5yM6IWx1xUW5S5uc2od7'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {

                        props.commonStore.changeStateDic(data[newSPCINT]['polygon'], newSPCINT, 'polygonCollection');
                        props.commonStore.changeState('polygonPoints',data[newSPCINT]['polygon'])

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            } else {
                props.commonStore.changeState('polygonPoints',polygonCollection[newSPCINT])
            }

            props.commonStore.changeState('selectedSPCINT',  newSPCINT)



        })()
    };





    return (
        <div>
            <div >

                    {values.spcintList.slice(values.rank, values.rank+1).map((spc, i)=>( <div key={i}>
                            { statsResults[spc]!==undefined?<Paper
                                elevation={parseInt(spc)===selectedSPCINT?24:0}
                                style={{ backgroundColor: parseInt(spc)===selectedSPCINT?  ColorPlatte()[Math.min(20-Math.ceil(statsResults[selectedSPCINT][`sum`]/5),19)]: null, border:'1px white solid'}}
                            >
                                <CardContainerExplore data = {statsResults[spc]} spc_int={spc} rank={values.rank+1} />
                            </Paper> : null }</div>
                    ))}

                <MobileStepper
                    variant="text"
                    steps={values.spcintList.length}
                    position="static"
                    activeStep={values.rank}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleStep(1)}
                            disabled={values.rank === values.spcintList.length - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleStep(-1)} disabled={values.rank === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            </div>
        </div>
    );
}

export default inject('commonStore', 'routerStore', 'seoStore') (observer(ExploreResultMobile));