import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import "./areacard.css";
import {Chip, Grid, Input} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import Rating from '@mui/material/Rating';
import Collapse from '@mui/material/Collapse';
import {RateReviewOutlined} from "@material-ui/icons";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {v4 as uuidv4} from 'uuid';
import {useCookies} from "react-cookie";


function ReviewSubmission(props) {

    const {ratings, remainingTagList, selectedSPCINT, statsResults, reviews, comments, displayOptions} = props.commonStore;
    const [cookies, setCookie] = useCookies();

    const filter = createFilterOptions();
    const [values, setValues] = React.useState({
        comment: "Share your thoughts about this neighbourhood",
        rating: null,
        tags: [],
        ratings: ratings['template'].reduce(function (a, b) {
           a[b['content']]=0;
           return a;
        },{}),
        submissionID: '',
        userID: 'anonymous',
        matchingSubID:null
    });

    useEffect(() => {
        (async () => {

            let cookieExist = false;
            let matchingSubID = null;
            let commentList = comments[selectedSPCINT].reduce((a, b, i)=>{
                a.push(b['SubID']);
                return a;
            }, []);
            for (let i = 0; i < Object.keys(cookies).length; i++){
                if (cookies[commentList[i]]!==undefined) {
                    cookieExist = true;
                    matchingSubID = commentList[i]

                    break;
                }
            }
            if (cookieExist){

                setValues({
                    ...values,
                    comment: cookies[matchingSubID]['Comment'],
                    rating: cookies[matchingSubID]['Rating'],
                    tags: [...cookies[matchingSubID]['Tags']],
                    ratings: {...cookies[matchingSubID]['Ratings']},
                    submissionID: matchingSubID,
                    userID: cookies[matchingSubID]['UserID'],
                    matchingSubID: matchingSubID
                });
            } else {
                setValues({
                    ...values,
                    submissionID:  uuidv4()
                });
            }
        })()
    }, []);


    const handleChange=()=>(event)=>{
        setValues({...values, comment: event.target.value})
    };

    const handleChangeName=()=>(event)=>{
        setValues({...values, userID: event.target.value})
    };


    const handleFocus=()=>(event)=>{

        if (values.comment ==="Share your thoughts about this neighbourhood"){
            setValues({...values, comment: ""})
        }

    };

    const handleClick = () => ()=>{


        props.commonStore.changeStateDic(!displayOptions.reviewOpen, 'reviewOpen', 'displayOptions')

    };


    const paramsGeneration = (spc_int, lat,lng, content, type,  rate, uid, subID, userID)=> {
        let date = new Date().toISOString().slice(0,10);
        let params = {
            "write_flag":0,
            "Content": content,
            "Content_Type": type,
            "Latitude": lat,
            "Latitude_Div": Math.round(lat*40)/40,
            "Longitude": lng,
            "Longitude_Div": Math.round(lng*20)/20,
            "SPC_INT": spc_int,
            "UID": uid,
            "Date": date,
            "SubID": subID,
            "UserID": userID
        }
        if (type==='Rating'){
             params['Rate'] = rate
        } else if (type ==='Tag' ||type ==='Comment' ||type==="Tag_DisplayOnly"){
            params['Upvote'] = 0
            params['Downvote'] = 0
        }
        return Object.keys( params ).map( function(key){ return key+"="+params[key] }).join("&");

    }




    const onSubmission = () => () =>{
        (async () => {
            props.commonStore.changeStateDic(true, 'backendRunning', 'displayOptions')
            let data = {};
            let cookieValue = {[values.submissionID]:  { UserID: values.userID, SubDate:new Date().toISOString().slice(0,10),Rating: 0, Comment: '', Ratings: ratings['template'].reduce(function (a, b) {
                        a[b['content']]=0;
                        return a;
                    },{}), Tags: []}
            };
            if (values.rating !==null){

                let paramsString = paramsGeneration(selectedSPCINT, statsResults[selectedSPCINT]['geo']['lat'], statsResults[selectedSPCINT]['geo']['lng'],
                    'Overall', 'Rating', values.rating, uuidv4(),values.submissionID, values.userID)
                await fetch(`https://85t40xqu90.execute-api.eu-west-1.amazonaws.com/default/dynamodb_putitem_js?${paramsString}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key':'RIvsn7Wc6Y5VOSrFyFWnk580y9YvaPhO9p1oPHy0'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then(() => {
                        cookieValue[values.submissionID]['Rating'] = values.rating
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }


            if (values.comment !==''&&values.comment !=='Share your thoughts about this neighbourhood'){

                let paramsString = paramsGeneration(selectedSPCINT, statsResults[selectedSPCINT]['geo']['lat'], statsResults[selectedSPCINT]['geo']['lng'],
                    values.comment, 'Comment', 0, uuidv4(),values.submissionID, values.userID)
                await fetch(`https://85t40xqu90.execute-api.eu-west-1.amazonaws.com/default/dynamodb_putitem_js?${paramsString}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key':'RIvsn7Wc6Y5VOSrFyFWnk580y9YvaPhO9p1oPHy0'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then(() => {
                        cookieValue[values.submissionID]['Comment'] = values.comment
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }

            for await (let content of Object.keys(values.ratings)){
                if (values.ratings[content]!==0){
                    let paramsString = paramsGeneration(selectedSPCINT, statsResults[selectedSPCINT]['geo']['lat'], statsResults[selectedSPCINT]['geo']['lng'],
                        content, 'Rating', values.ratings[content], uuidv4(),values.submissionID, values.userID)
                    await fetch(`https://85t40xqu90.execute-api.eu-west-1.amazonaws.com/default/dynamodb_putitem_js?${paramsString}`, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key':'RIvsn7Wc6Y5VOSrFyFWnk580y9YvaPhO9p1oPHy0'
                        },
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then(() => {
                            cookieValue[values.submissionID]['Ratings'][content] = values.ratings[content]
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }
            }


            for await (let tag of values.tags){
                if (statsResults[selectedSPCINT]['review']['tags'].findIndex(x => x.content === tag)>=0){
                    let paramsString = paramsGeneration(selectedSPCINT, statsResults[selectedSPCINT]['geo']['lat'], statsResults[selectedSPCINT]['geo']['lng'],
                        tag, 'Tag_DisplayOnly', 0, uuidv4(),values.submissionID, values.userID)
                    let upvoteUID =statsResults[selectedSPCINT]['review']['tags'][statsResults[selectedSPCINT]['review']['tags'].findIndex(x => x.content === tag)]['uid']

                    await fetch(`https://85t40xqu90.execute-api.eu-west-1.amazonaws.com/default/dynamodb_putitem_js?${paramsString}`, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key':'RIvsn7Wc6Y5VOSrFyFWnk580y9YvaPhO9p1oPHy0'
                        },
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then(() => {
                            cookieValue[values.submissionID]['Tags'] = [...cookieValue[values.submissionID]['Tags'], tag ]
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });

                    await fetch(`https://ru9bd696y8.execute-api.eu-west-1.amazonaws.com/default/dynamodb_writer_js?key=${upvoteUID}&vote_type=Upvote&increment=1`, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key':'U9VH4xLINz8YPHx0lgIE51quNWHmJbNT5Eo2bTw4'
                        },
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then(() => {
                            let reviewCategory = 'Whole_Area';
                            let findIndex = reviews[selectedSPCINT]['tags'][reviewCategory].findIndex(x => x.uid === upvoteUID);
                            let newItem = {...reviews[selectedSPCINT]['tags'][reviewCategory][findIndex]};
                            newItem['upvote'] = newItem['upvote'] + 1;
                            let newReviewCategoryItems =[...reviews[selectedSPCINT]['tags'][reviewCategory]];
                            newReviewCategoryItems[findIndex] = {...newItem};
                            let newReviewSPCINTTags = {
                                ...reviews[selectedSPCINT]['tags'],
                                [reviewCategory]: newReviewCategoryItems,
                            };
                            let newReview = {
                                ...reviews[selectedSPCINT],
                                tags: newReviewSPCINTTags,
                            };
                            props.commonStore.changeStateDic(newReview, selectedSPCINT, 'reviews');


                            let findIndex2 = statsResults[selectedSPCINT]['review']['tags'].findIndex(x => x.uid === upvoteUID);
                            let newStatsTagsItems =[...statsResults[selectedSPCINT]['review']['tags']];

                            newStatsTagsItems[findIndex2] = {...newItem};
                            let newStatsSPCINTTags = {
                                ...statsResults[selectedSPCINT]['review'],
                                'tags': newStatsTagsItems,
                            };
                            let newStats = {
                                ...statsResults[selectedSPCINT],
                                'review': newStatsSPCINTTags,
                            };
                            props.commonStore.changeStateDic(newStats, selectedSPCINT, 'statsResults');
                            setCookie(upvoteUID, 1, { maxAge: 365 * 24 * 60 * 60, sameSite: 'strict'})
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                } else {
                    let uid = uuidv4()
                    let paramsString = paramsGeneration(selectedSPCINT, statsResults[selectedSPCINT]['geo']['lat'], statsResults[selectedSPCINT]['geo']['lng'],
                        tag, 'Tag', 0, uid,values.submissionID, values.userID)
                    await fetch(`https://85t40xqu90.execute-api.eu-west-1.amazonaws.com/default/dynamodb_putitem_js?${paramsString}`, {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key':'RIvsn7Wc6Y5VOSrFyFWnk580y9YvaPhO9p1oPHy0'
                        },
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then(() => {
                            cookieValue[values.submissionID]['Tags'] = [...cookieValue[values.submissionID]['Tags'], tag ]

                            let reviewCategory = 'Whole_Area';
                            let newItem = {
                                "content": tag,
                                "upvote": 0,
                                "downvote": 0,
                                "uid": uid
                            };
                            let newReviewCategoryItems =[...reviews[selectedSPCINT]['tags'][reviewCategory], newItem];
                            let newReviewSPCINTTags = {
                                ...reviews[selectedSPCINT]['tags'],
                                [reviewCategory]: newReviewCategoryItems,
                            };
                            let newReview = {
                                ...reviews[selectedSPCINT],
                                tags: newReviewSPCINTTags,
                            };
                            props.commonStore.changeStateDic(newReview, selectedSPCINT, 'reviews');


                            let newStatsTagsItems =[...statsResults[selectedSPCINT]['review']['tags'], newItem];

                            let newStatsSPCINTTags = {
                                ...statsResults[selectedSPCINT]['review'],
                                'tags': newStatsTagsItems,
                            };
                            let newStats = {
                                ...statsResults[selectedSPCINT],
                                'review': newStatsSPCINTTags,
                            };

                            props.commonStore.changeStateDic(newStats, selectedSPCINT, 'statsResults');
                            setCookie(uid, 'create', { maxAge: 365 * 24 * 60 * 60, sameSite: 'strict'})


                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });

                }
            }

            setCookie([values.submissionID], cookieValue[values.submissionID], { maxAge: 365 * 24 * 60 * 60, sameSite: 'strict'})

            let spcComments = [...comments[selectedSPCINT]]

            if (values.matchingSubID){

                spcComments = [cookieValue[values.submissionID], ...spcComments.filter(x=> x.SubID !==values.matchingSubID)]

            } else {
                spcComments = [cookieValue[values.submissionID], ...spcComments]
            }


            props.commonStore.changeState('comments', {...comments, [selectedSPCINT]:  [...spcComments ]})
            props.commonStore.changeStateDic(false, 'reviewOpen', 'displayOptions')
            props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions')

        })()


    }


    return (
        <div className="">

            <Button variant="contained" onClick={handleClick()} startIcon={<RateReviewOutlined />} style={{padding:'5px'}} color="inherit">
                Write a review
            </Button>

            <Collapse in={displayOptions.reviewOpen}>

                <div style={{marginTop:"10px", marginBottom:"10px"}}>
                    <span style={{color:'grey', fontSize:'12px', fontWeight:'bold', margin:'5px' }}>
                    Your Name:
                    </span>
                    <Input defaultValue={values.userID}  onChange={handleChangeName()} />
                </div>

                    <div style={{width:'100%', textAlign:'center'}}>

                    <Rating
                        name="simple-controlled"
                        value={values.rating}
                        onChange={(event, newValue) => {
                            setValues({...values, rating: newValue});
                        }}
                    />
                    </div>


                <Autocomplete multiple id="tags-filled" options={remainingTagList} style={{minWidth: '50%', marginBottom:'10px'}} freeSolo value={values.tags} size="small"
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                  ))
                              }
                              renderInput={params => (
                                  <TextField {...params} label="Add Tags" placeholder="Search" variant="standard" InputLabelProps={{style: {fontSize: 15}}}/>
                              )}
                              onInputChange={(event, value) =>{
                                  setValues({ ...values, 'newTagText': value});
                              } }
                              onChange={(event, newValue) => {

                                  setValues({ ...values, tags: [...newValue]});
                                  props.commonStore.changeState('selectedTag', [...newValue] )
                              }}
                              filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (params.inputValue !== '') {
                                      filtered.push(`${params.inputValue}`);
                                  }
                                  return filtered;
                              }}

                />

                <textarea name="text" wrap="soft" value={values.comment} onChange={handleChange()} onFocus = {handleFocus()}
                    style={{height:'80px', width:'100%', fontSize:'14px', padding:'5px', borderRadius:"5px", border:"1px lightgrey solid", color:'grey', resize:'none'}}
                >

                </textarea>


                    <Grid container direction='column'>
                        {Object.keys(values.ratings).map((rating, i)=>(
                            <Grid container  alignItems="center" key={i} style={{paddingBottom:'10px'}}>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                               <span style={{color:'grey', fontSize:'12px', fontWeight:'bold'}}>
                                   {rating}
                               </span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Rating
                                        name="simple-controlled"
                                        value={values.ratings[rating]}
                                        onChange={(event, newValue) => {
                                            setValues({...values, ratings: {...values.ratings, [rating]: newValue}});
                                        }}
                                        size="small"
                                    />

                                </Grid>
                            </Grid>
                        ))}
                    </Grid>



                <Button variant="outlined" onClick={handleClick()}  style={{marginRight:'5px'}}>
                    Cancel
                </Button>

                <Button variant={(values.rating===null ?"outlined":"contained")} disabled={values.rating===null} onClick={onSubmission()}  >
                    {displayOptions.backendRunning?<img
                        src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                        alt="thank-you"
                        style={{display: 'block', margin: 'auto', width: '20px',}}
                    /> : `Post` }
                </Button>


            </Collapse>




        </div>

    )

}


export default inject('commonStore', 'routerStore') (observer(ReviewSubmission));