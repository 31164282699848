import React from 'react';
import {inject} from "mobx-react";


function LandingBlog(props) {

    const {blogIndex} = props.commonStore;

    return (
        <div >
            <div style={{backgroundColor:'white',   }}>
                <ul className="mdc-list"  style={{width:'96%', height:'96%', padding:'1%' }}>
                    <a  href="/blog"  style={{ color:'black', textDecoration: 'none'}}>
                        <li className="mdc-list-item"   >
                            <span className="mdc-typography--headline5">Our blogs</span>
                        </li>
                    </a>
                    {(blogIndex.length!==0?   [0,1].map((category)=>(
                        blogIndex[category]['blogList'].slice(0,2).map((data, i)=>(
                                <a key={i}  href={`${data.url}`}  style={{  textDecoration: 'none'}}>
                                    <li className="mdc-list-item" style={{ borderTop:'1px solid #e6ecf0', padding:'2%'  }}>

                                        <span className="mdc-list-item__ripple"></span>
                                        <div className="mdc-layout-grid__inner" key={i} style={{ padding:'1%', margin:'1%', width:'96%', color:' #202020',  }}>
                                            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-8">
                                                <div style={{display:'flex', flexDirection:'column'}}>
                                                    <div ><span className="mdc-typography--subtitle2" >{data.label}</span></div>
                                                    <div ><span className="mdc-typography--caption">{data.date}</span></div>
                                                </div>
                                            </div>
                                            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
                                                <img src={`${data.img}`}
                                                     alt={`${data.label}`}
                                                     style={{ height:'70px', borderRadius:'8%'}}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </a>
                            )
                        ))
                    ):null)}
                    <li className="mdc-list-item"  style={{ borderTop:'1px solid #e6ecf0',  }} >
                        <a  href="/blog"  style={{ color:'green', textDecoration: 'none'}}>
                            <span className="mdc-typography--body1">Show more</span>
                        </a>
                    </li>

                </ul>

            </div>



        </div>
    );
}

export default inject('commonStore') (LandingBlog);