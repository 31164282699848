import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from "./App";

import {createBrowserHistory} from 'history';
import {RouterStore, syncHistoryWithStore} from 'mobx-react-router';
import {Router} from "react-router-dom";
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import {CookiesProvider} from 'react-cookie';

import commonStore from './stores/commonStore';
import seoStore from './stores/seoStore';


const browserHistory = createBrowserHistory();
const routerStore = new RouterStore();

const stores = {
    commonStore,
    seoStore,
    routerStore,
};

const history = syncHistoryWithStore(browserHistory, routerStore);

window._____APP_STATE_____ = stores;
configure({ enforceActions: 'always' });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

        <CookiesProvider>
        <Provider {...stores}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
        </CookiesProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
