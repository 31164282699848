import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import Grid from '@mui/material/Grid';


function MenuMobile(props) {
    const {location, push} = props.routerStore;

    const returnHome = () =>()=>{
        push('/home')
    };


    return (
        <Grid container  justifyContent="space-around"   alignItems="center" style={{width:'100vw',backgroundColor:'white',  overflow:'hidden' }}>

<Grid item xs={2} style={{textAlign:'center'}}>
            <a  href="/home"  style={{ textDecoration: 'none'}}>

                <span className="mdc-typography--caption" style={{color:'#8cb47d', fontWeight:'bold'}} >Home</span>

            </a>
</Grid>
            <span style={{color:'#DEDEDE'}}>|</span>
            <Grid item xs={2} style={{textAlign:'center'}}>
            <a  href="/searchstats?lat=51.5072178&lng=-0.1275862&factors=safety,employment"  style={{ textDecoration: 'none'}}>

                <span className="mdc-typography--caption" style={{color:'#A5A5A5', fontWeight:'bold'}} >Area Search</span>

            </a>
            </Grid>
            <span style={{color:'#DEDEDE'}}>|</span>
            <Grid item xs={2} style={{textAlign:'center'}}>
            <a  href="/explore?commute0=51.5072178,-0.1275862,30,drive&factors=commuting,34,safety,34,employment,34"  style={{textDecoration: 'none'}}>

                <span className="mdc-typography--caption" style={{color:'#A5A5A5', fontWeight:'bold'}}>Area Explore </span>

            </a>
            </Grid>
            <span style={{color:'#DEDEDE'}}>|</span>
            <Grid item xs={2} style={{textAlign:'center'}}>
            <a  href="/district-profile/city-of-london"  style={{textDecoration: 'none'}}>

                <span className="mdc-typography--caption" style={{color:'#64b5f6', fontWeight:'bold'}} >District Profile</span>

            </a>
            </Grid>
            <span style={{color:'#DEDEDE'}}>|</span>
            <Grid item xs={1} style={{textAlign:'center'}}>
            <a  href="/blog"  style={{textDecoration: 'none' }}>
                <span className="mdc-typography--caption" style={{color:'#FFC000', fontWeight:'bold'}}>Blog</span>
            </a>
            </Grid>
            <span style={{color:'#DEDEDE'}}>|</span>
            <Grid item xs={1} style={{textAlign:'center'}}>
            <a  href="/about-us"  style={{ textDecoration: 'none'}}>

                <span className="mdc-typography--caption" style={{color:'#ED7D31', fontWeight:'bold'}}>About Us</span>

            </a>
            </Grid>


        </Grid>

    );
}

export default inject('commonStore', 'routerStore') (observer(MenuMobile));