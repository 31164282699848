/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {inject, observer} from "mobx-react";
import IconButton from "@material-ui/core/IconButton";
import {Search} from "@material-ui/icons";



function DistrictDropdown(props) {
     const {location, push} = props.routerStore;
     const {districtList} = props.seoStore;
     const [values, setValues] = React.useState({
         district: null,
     });


    return (
        <div style={{ display:'flex', flexDirection:'row', marginLeft:'10px' }}>
            <div style={{width:250}}>
            <Autocomplete
                freeSolo
                id="search district"
                value={(districtList[`${location.pathname.replace('/district-profile/','')}`]? districtList[`${location.pathname.replace('/district-profile/','')}`]['name']:null)}
                disableClearable
                size='small'
                options={Object.keys(districtList).map(option => districtList[`${option}`]['name'])}
                onChange={(event, newValue) => {
                    let newList = Object.keys(districtList).reduce(function (a, b) {
                        a[`${districtList[`${b}`]['name']}`] = b;
                        return a;
                    }, {});
                    setValues({district: newList[newValue]});
                    window.open(`/district-profile/${newList[newValue]}`, '_self');

                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Search District"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                    />

                )}
            />
            </div>
            <IconButton
                aria-label="editsearch"
                size="medium"
                href={`/district-profile/${values['district']}`}
            >
               <Search />
            </IconButton>
        </div>
    );
}

export default inject('commonStore', 'routerStore', 'seoStore')  (observer(DistrictDropdown));