import React from 'react';
import {inject, observer} from "mobx-react";
import MapContainerSearch from "../map/MapContainerSearch";
import SearchBoxMobile from "./SearchBoxMobile";
import SearchResultMobile from "./SearchResultMobile";
import AreaCard from "../area-card/AreaCard";
import SearchFactors from "./SearchFactors";
import Notification from "../Notification";
import {Search} from '@material-ui/icons';
import Collapse from '@mui/material/Collapse';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import "./searchresultcontainer.css";


function SearchResultContainerMobile(props) {

    const {displayOptions, defaultCenter, polygonPoints} = props.commonStore;


    const onSearch = ()=> ()=>{
        props.commonStore.changeStateDic(!displayOptions.sideOpen2, 'sideOpen2', 'displayOptions')
    }


    return (
        <div className="search-result-root-mobile">

            <div>

                    {displayOptions.backendRunning?<img
                        src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                        alt="thank-you"
                        style={{display: 'block', margin: 'auto', width: '20px',}}
                    /> :

                        <Collapse orientation="horizontal" in={displayOptions.sideOpen2}>
                        < div className="search-result-searchbox-mobile"><SearchBoxMobile/></div>
                            <div className="search-result-factors-mobile"><SearchFactors/> </div>

                        </Collapse>}

                <Zoom in={!displayOptions.sideOpen2} style={{ transitionDelay: !displayOptions.sideOpen2 ? '300ms' : '0ms' }}>
                    <Fab color="success" aria-label="search"  size="small"
                         style={{position:'fixed', zIndex:3}} onClick ={onSearch()}
                         disabled = {displayOptions.backendRunning}
                    >
                        {displayOptions.backendRunning?<img
                            src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                            alt="thank-you"
                            style={{display: 'block', margin: 'auto', width: '20px',}}
                        /> :  <Search />}
                    </Fab>
                </Zoom>

                <div className="search-result-resultlist-mobile"><SearchResultMobile/></div>
                {displayOptions.areaCardOn ? <div className="search-result-area-mobile"><AreaCard/></div>: null }

                <div className="search-result-map-mobile">
                    <MapContainerSearch center={defaultCenter} points={polygonPoints}/>
                </div>

            </div>

            <Notification/>

        </div>

    )

}


export default inject('commonStore', 'routerStore') (observer(SearchResultContainerMobile));


/*



      */



