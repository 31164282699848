import React from 'react';
import {inject, observer} from "mobx-react";
import DistrictData from "./DistrictData";
import Grid from '@mui/material/Grid';
import "./district.css";
import DistrictComments from "./DistrictComments";
import DistrictDropdown from "./DistrictDropdown";


function District(props) {


    return (
        <div className="district-root" >

                <Grid >
                    <Grid  container spacing={2}>
                        <Grid item xs={12} md={8} >
                            <DistrictDropdown/>
                            <DistrictData/>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <DistrictComments/>
                        </Grid>
                    </Grid>
                </Grid>

        </div>
    );
}



export default inject('commonStore', 'routerStore', 'seoStore') (observer(District));