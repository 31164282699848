import React, {useEffect} from 'react';
import {
    Button,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import {inject, observer} from "mobx-react";



const useStyles = makeStyles(theme => ({
    root: {flexGrow: 1, display:'flex', justifyContent:'center', height:'78vh',},
}));

function DistrictData(props) {
    const classes = useStyles();
    const {location, push} = props.routerStore;
    const {districtFullFactors,  displayOptions} = props.commonStore;
    const {districtList} = props.seoStore;

    const [values, setValues] = React.useState({
        stats: {},
    });

    const LargeTooltip = withStyles(theme => ({
        tooltip: {
            fontSize: 13,
        },
    }))(Tooltip);

    const numberFormat = (number) =>{
        if(isNaN(number)){
            return number
        } else if (number > 1000){
            return Math.ceil(number).toLocaleString()

        }else if (number > 100) {
            return Math.ceil(number)
        }else {
            return Math.ceil(number*100)/100
        }
    };

    useEffect(() => {
        (async () => {
            if (location.pathname.startsWith('/district-profile') && location.pathname !=='/district-profile') {
                let district_code = districtList[`${location.pathname.substring(18)}`]['code'];

                await fetch(`https://2po5wpliek.execute-api.eu-west-1.amazonaws.com/default/district_reader?district_code=${district_code}`)
                    .then(response => response.json())
                    .then(function(data){
                        if(data['Items'].length>0){

                            setValues({stats: data['Items'][0] });
                        } else {
                            push('/district-profile/city-of-london');
                        }
                    })
                    .catch(function(error){
                        console.log(error)
                    });

            }
        })();
    }, [location.pathname]);


    return (
        <div className={classes.root}>
            <TableContainer >
                <Toolbar>
                    <div style={{display:'flex', flexDirection:'column'}}>
                    <Typography variant="caption" >Score based on ranking among all districts. National average is 50</Typography>
                    </div>

                </Toolbar>
                <Table >
                    <TableHead>
                        <TableRow >
                            <TableCell >Factor</TableCell>
                            <TableCell align="right">Our Score</TableCell>
                            <TableCell align="right">Actual Value</TableCell>
                            {(displayOptions.screenSize>1000?  <TableCell align="right">Useful Link</TableCell>: null )}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {districtFullFactors.map((factor, i) => (
                            <LargeTooltip title={factor.hint} key={i}>
                            <TableRow hover key={i} style={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word'
                            }}>
                                <TableCell component="th" scope="row">{factor[`label`]}</TableCell>
                                <TableCell align="right">{numberFormat(values.stats[`${factor['key'].substring(10)}_District`])}</TableCell>
                                <TableCell align="right">{numberFormat(values.stats[`${factor['key'].substring(10)}_Actual_District`])}{factor['unit']}</TableCell>
                                {(displayOptions.screenSize>1000? <TableCell align="right"><Button href={factor.url} target="_blank" size="small">
                                    {factor.site}
                                </Button></TableCell>: null)}

                            </TableRow>
                            </LargeTooltip>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default inject('commonStore', 'routerStore', 'seoStore') (observer(DistrictData));
