import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";


const xss = require("xss");



function BlogPage(props) {
    const {location, push} = props.routerStore;
    const [values, setValues] = React.useState({
        loaded: false,
        clean: '',
    });

    useEffect(() => {
        (async () => {
            let pageName = location.pathname.substring(6);
            await fetch(`https://0n0z03wb8a.execute-api.eu-west-1.amazonaws.com/default/blog_reader?page_name=${pageName}`)
                .then(response => response.json())
                .then(function(data){
                    if(data.length ===0){push('/blog');} else{setValues({loaded: true, clean: data})}
                })
                .catch(function(error){
                    push('/blog');
                    console.log(error)
                });



        })();
    }, []);


    return (


        <div style={{backgroundColor:'white', minWidth:'1000px'}}>
            {values.loaded? <div style={{padding:'10px'}}>
                    <button className="mdc-button mdc-button--unelevated" onClick={()=>{window.location= '/blog'}}>
                        <span className="mdc-button__label">Back</span>
                    </button>
                    <div dangerouslySetInnerHTML={{__html: values.clean}} /> </div> :


                <div style={{textAlign:'center'}}> Loading </div>}
        </div>

    );



}

export default inject('commonStore', 'routerStore') (observer(BlogPage));
