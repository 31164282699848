import React from "react";
import {inject, observer} from "mobx-react";

import {Route, Switch} from "react-router-dom";

import Landing from "./components/landing/Landing";
import SearchResultContainer from "./components/area-search/SearchResultContainer";
import SearchResultContainerMobile from "./components/area-search/SearchResultContainerMobile";
import ExploreResultContainer from "./components/area-explore/ExploreResultContainer"
import ExploreResultContainerMobile from "./components/area-explore/ExploreResultContainerMobile";
import District from "./components/district-profile/District";
import Blog from "./components/blog/Blog";
import AboutUs from "./components/about-us/AboutUs";
import Menu from "./components/Menu";
import MenuMobile from "./components/MenuMobile";
import "./app.css";


function App(props) {

    const {displayOptions} = props.commonStore;
    props.commonStore.changeStateDic(window.innerWidth, 'screenSize', 'displayOptions');

    return (
        <div className={displayOptions.screenSize >= 1280? "app-root": "app-root-mobile"}>

            {displayOptions.sideOpen && displayOptions.screenSize >= 1280? <div className="app-menu"> <Menu/></div>:
                <div className="app-menu-mobile"> <MenuMobile/></div>}


            <Switch>
                <Route path="/home" ><Landing/></Route>
                <Route path="/searchstats" > {displayOptions.screenSize >= 1280? <SearchResultContainer/>: <SearchResultContainerMobile/>}</Route>
                <Route path="/explore" >{displayOptions.screenSize >= 1280?<ExploreResultContainer/> : <ExploreResultContainerMobile/>} </Route>
                <Route path="/district-profile" ><District/></Route>
                <Route path="/blog" ><Blog/></Route>
                <Route path="/about-us"><AboutUs/> </Route>
                <Route path="*" ><Landing/> </Route>
            </Switch>
        </div>

    );
}

export default inject('commonStore','routerStore')(observer(App));
