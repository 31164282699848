import React, {Component} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Typography
} from '@material-ui/core';
import {ArrowBackIos, Cancel, Search, Timer} from '@material-ui/icons';
import {inject, observer} from "mobx-react";


const searchOptions = {
    componentRestrictions: {
        country: 'gb'
    },
    types: []
};

class ExploreBoxMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '' ,
            message: '',
            time:30,
            transportation:"walk"
        };
    }


    componentDidMount() {
        try {
        let exploreCriteria = this.props.routerStore.location.search.slice(1).split('&').reduce(function (a,b) {
            a[b.split('=')[0]] = b.split('=')[1].split(',')
            return a;
        },{})

        this.setState({
            ...this.state,
            address: this.props.commonStore.tempAddress,
            time: parseInt(exploreCriteria['commute0'][2]),
            transportation: exploreCriteria['commute0'][3]

        })}catch(e){

        }

    };


    handleChangeInput = name => event => {

        this.setState({...this.state, [name]: event.target.value})
    };


    handleChange = address => {
        this.setState({ address });
        this.props.commonStore.changeState('tempAddress', address);

    };

    onCancel = ()=>()=>{
        this.setState({...this.state, address: ''});
        this.props.commonStore.changeState('tempAddress', '')
    };

    onCollapse = ()=> ()=>{
        this.props.commonStore.changeStateDic(!this.props.commonStore.displayOptions.sideOpen2, 'sideOpen2', 'displayOptions')
    }


    handleSelect = address => {
        this.setState({ address });
        this.props.commonStore.changeState('tempAddress', address);

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng =>
                {
                    this.props.commonStore.changeState('searchGeo', {lat:latLng.lat, lng: latLng.lng  } );
                    this.props.commonStore.changeState('searchText', address);
                    this.props.commonStore.changeState([{lat: this.props.commonStore.searchGeo.lat, lng: this.props.commonStore.searchGeo.lng}, ...this.props.commonStore.queryMarkers], 'queryMarkers')
                }
            )
            .catch(error => {
                console.error(error);

            });
    };



    onError = (status, clearSuggestions) => {
        clearSuggestions();
    };


    onSubmission = () => {

        let commute0 = `${this.props.commonStore.searchGeo.lat},${this.props.commonStore.searchGeo.lng},${this.state.time},${this.state.transportation}`

         let factorString = this.props.commonStore.exploreSelectedFactors.reduce(function(a, b, i){
             a = [...a,
                 b['label'].toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_'),
                 parseInt(b['score'])
             ]
             return a;
         }, []).join()


        window.open(`/explore?commute0=${commute0}&factors=${factorString}`, '_self')

     };



    render(){

        return (

            <Paper style={{display: 'flex', displayDirection:'row', justify:'flex-start',alignItems:'center'}}>
                <div style={{display: 'flex', displayDirection:'row', justify:'flex-start', flexWrap:'wrap', alignItems:'center'}}>
                    <Typography variant='body1' gutterBottom align="left">Explore areas</Typography>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', flexWrap:'wrap'}}>
                        <Timer/>
                        <Input
                            id="standard-number"
                            value={this.state.time}
                            onChange={this.handleChangeInput('time')}
                            type="number"
                            style={{width: '50px'}}
                        />
                    </div>

                    <Typography variant='body1'>mins from </Typography>

                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onError={this.onError}
                        onSelect={this.handleSelect}
                        searchOptions = {searchOptions}

                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div        style={{width:'170px'}}>
                                <Grid container alignItems="center">
                                    <Input
                                        {...getInputProps({
                                            placeholder: 'England Only ...',
                                        })}
                                        disableUnderline={false}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="delete" style={{color:'dimGray'}} onClick ={this.onCancel()}>
                                                    <Cancel fontSize="inherit" />
                                                </IconButton>

                                            </InputAdornment>
                                        }
                                    />

                                </Grid>

                                <div
                                    className="autocomplete-dropdown-container"
                                    style={{position:'absolute',
                                        zIndex:100,
                                        display: 'flex',
                                        flexDirection:'column',
                                        backgroundColor:'white',
                                        margin:'5px',

                                    }}
                                >
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <MenuItem
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </MenuItem>
                                        );
                                    })}
                                </div>

                            </div>
                        )}
                    </PlacesAutocomplete>

                    <Typography variant='body1'>By</Typography>
                    <FormControl >
                        <Select
                            value={this.state.transportation}
                            onChange={this.handleChangeInput('transportation')}
                            inputProps={{
                                name: 'transportation',
                                id: 'transportation-auto-width',
                            }}
                            autoWidth
                            style={{margin:'5px'}}
                        >
                            <MenuItem value={'drive'}><Typography variant='body1'> Driving </Typography> </MenuItem>

                            <MenuItem value={'walk'}><Typography variant='body1'>  Walking </Typography> </MenuItem>
                        </Select>
                    </FormControl>
                    <IconButton
                        aria-label="editsearch"
                        size={"medium"}
                        onClick={this.onSubmission}

                    >
                        {this.props.commonStore.displayOptions.backendRunning?<img
                            src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                            alt="thank-you"
                            style={{display: 'block', margin: 'auto', width: '20px',}}
                        />: <Search />}
                    </IconButton>
                    <div style={{ color:"lightGrey", fontSize:"20px", height:"30px", display:'flex', alignItems:'center'}}>|</div>
                    <IconButton aria-label="delete" style={{color:'dimGray'}} onClick ={this.onCollapse()}>
                        {this.props.commonStore.displayOptions.backendRunning?<img
                            src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                            alt="loading"
                            style={{display: 'block', margin: 'auto', width: '20px',}}
                        />: <ArrowBackIos />}
                    </IconButton>
                </div>

            </Paper>

        )
    }
}

export default inject('commonStore', 'routerStore') (observer(ExploreBoxMobile));


/*
<MenuItem value={'public_transport'}><Typography variant='body1'> Public Transport </Typography></MenuItem>


 */