import React from 'react';
import {inject, observer} from "mobx-react";
import "./areacard.css";
import FlagContainerSearch from "../result-card/FlagContainerSearch";
import RatingScore from "./RatingScore";
import ReviewSubmission from "./ReviewSubmission";
import Comment from "./Comment";
import TagBoxwithVote from "./TagBoxwithVote";
import {Cancel} from "@material-ui/icons";
import Fab from "@mui/material/Fab";


function AreaCard(props) {

    const {statsResults, selectedSPCINT, ratings, comments } = props.commonStore;

    const [values, setValues] = React.useState({
        len: 5,

    });

    const onClick = () =>() =>{
        props.commonStore.changeStateDic(false, 'areaCardOn', 'displayOptions' )
    }

    const onClickLen = (i) =>() =>{
       setValues ({...values, len: values.len+i})
    }





    return (
        <div className="areacard-root">
            <div className="areacard-flag">
                <FlagContainerSearch
                data = {{flags:statsResults[selectedSPCINT]["review"]["flags"], address:statsResults[selectedSPCINT]["address"]}}
                spc_int = {selectedSPCINT}/>


                <Fab aria-label="search"  size="small"  onClick ={onClick()}>
                    <Cancel />
                </Fab>
            </div>

            <div className="areacard-rating"><RatingScore data={(ratings[selectedSPCINT]? ratings[selectedSPCINT]: ratings['template'] )}/></div>

            <div className="areacard-tag">
            {statsResults[selectedSPCINT]['review']['tags'].slice(0,values.len).map((tag, i)=>(
                   <TagBoxwithVote item ={tag} key={i}/>
                ))
            }
                {values.len < statsResults[selectedSPCINT]['review']['tags'].length &&
                <div className="areacard-tag-showmore" onClick={onClickLen(5)}>
                    <span className="mdc-typography--body2">Show more</span>
                </div>

                }

            </div>
            <div className="areacard-review">
                {comments[selectedSPCINT]?  <ReviewSubmission/>: null

                }

            </div>
            <div className="areacard-comment">
               <Comment/>
            </div>

        </div>

    )

}


export default inject('commonStore', 'routerStore') (observer(AreaCard));


