import React, {Component} from 'react';
import {GoogleApiWrapper, Map, Polygon, Marker, InfoWindow} from 'google-maps-react';
import {inject, observer} from "mobx-react";
import SilverMapStyles from "./MapStyle";
import ColorPlatte from "./ColorPlatte";

/* global google */


class MapContainerSearch extends Component {

    constructor(props){
        super(props);
        this.state={
            infoWindowContent: {type: null, spc_int: null},
            activeMarker: {},
        };
    }


    onReviewClick =(spc_int) => () =>{
        (async () => {
            let data = {};
            let polygonPoints = [];
            this.props.commonStore.changeStateDic(true, 'backendRunning', 'displayOptions');
            if (this.props.commonStore.statsResults[spc_int]===undefined){

                // obtain stats for selected three SPCs

                await fetch(`https://k0z0o11pg5.execute-api.eu-west-1.amazonaws.com/default/querySPCCard${this.props.routerStore.location.search}&spcintlist=${spc_int}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key':'aj7ooUNLkL3GtqQWhdop62IE0KlDnCJt8xE5I88J'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {

                        this.props.commonStore.changeState('statsResults', {...this.props.commonStore.statsResults, ...data})

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });



            } if (this.props.commonStore.polygonCollection[spc_int]===undefined){

                // obtain the spcint polygon
                await fetch(`https://3d1ktapzj0.execute-api.eu-west-1.amazonaws.com/default/spcPolygon?spcint=${spc_int}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key':'cguuxnQQ8EkHXIS2C1cM5yM6IWx1xUW5S5uc2od7'
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {

                        this.props.commonStore.changeStateDic(data[spc_int]['polygon'], spc_int, 'polygonCollection');
                        polygonPoints = [...polygonPoints, ...data[spc_int]['polygon']]
                        this.props.commonStore.changeState('polygonPoints',polygonPoints)

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });


            }

            this.props.commonStore.changeState('selectedSPCINT', parseInt(spc_int));
            this.props.commonStore.changeStateDic(false, 'backendRunning', 'displayOptions');
            this.props.commonStore.changeStateDic(true, 'areaCardOn', 'displayOptions')

        })()


    }



    render() {

        let bounds = new this.props.google.maps.LatLngBounds();

        for (let i = 0; i < this.props.commonStore.polygonPoints.length; i++) {
            bounds.extend(this.props.commonStore.polygonPoints[i]);
        }

        return (
            <Map
                initialCenter={this.props.commonStore.defaultCenter}
                center = {this.props.commonStore.defaultCenter}
                map={this.props.map}
                google={this.props.google}
                zoom={14}
                styles={SilverMapStyles}
                bounds={bounds}
                mapTypeControl = {false}
            >

                {this.props.commonStore.queryMarkers.map((marker, i) => (
                    <Marker
                        key={i}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        clickable
                    />
                ))}

                {( this.props.commonStore.polygonCollection[this.props.commonStore.selectedSPCINT] && this.props.commonStore.statsResults[this.props.commonStore.selectedSPCINT]?
                    <Polygon
                        key={this.props.commonStore.selectedSPCINT}
                        paths={this.props.commonStore.polygonCollection[this.props.commonStore.selectedSPCINT]}
                        fillColor={ColorPlatte()[Math.min(20-Math.ceil(this.props.commonStore.statsResults[this.props.commonStore.selectedSPCINT][`sum`]/5),19)]}
                        fillOpacity={0.35}
                        strokeColor={'white'}
                        strokeWeight={1}
                    >
                    </Polygon>  :  null )
                }

                {Object.keys(this.props.commonStore.reviews).map((spc_int, i) =>(
                    (this.props.commonStore.reviews[spc_int]['tags']['Whole_Area'].length !==0? <Marker
                        key={i}
                        position={{
                            lat: this.props.commonStore.reviews[spc_int]['lat']+Math.random()*0.00025,
                            lng: this.props.commonStore.reviews[spc_int]['lng']+Math.random()*0.0005
                        }}
                        icon={{
                            url:`https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/comments/Slide${this.props.commonStore.reviews[spc_int]['tags']['Whole_Area'].length}+copy.png`,
                            scaledSize: new google.maps.Size(37.5,25)
                        }}
                        onClick = {this.onReviewClick( spc_int)}
                        clickable
                    /> : null )
                ) ) }

                {Object.keys(this.props.commonStore.reviews).map((spc_int, i) =>(
                    (this.props.commonStore.reviews[spc_int]['tags']['Others'].length !==0? <Marker
                        key={i}
                        position={{
                            lat: this.props.commonStore.reviews[spc_int]['tags']['Others']['lat']+Math.random()*0.00025,
                            lng: this.props.commonStore.reviews[spc_int]['tags']['Others']['lng']+Math.random()*0.0005
                        }}
                        icon={{
                            url:`https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/comments/Slide${this.props.commonStore.reviews[spc_int]['tags']['Others'].length}+copy.png`,
                            scaledSize: new google.maps.Size(37.5,25)
                        }}

                        onClick = {this.onReviewClick( spc_int)}
                        clickable
                    /> : null )
                ) ) }


                {Object.keys(this.props.commonStore.statsResults).map((spc_int, i) =>(
                    (this.props.commonStore.statsResults[spc_int]['review']!==undefined? (this.props.commonStore.statsResults[spc_int]['review']['flags'].count !==0  && this.props.commonStore.statsResults[spc_int]['geo']!==undefined ? <Marker
                        key={i}
                        position={{
                            lat: this.props.commonStore.statsResults[spc_int]['geo']['lat']+Math.random()*0.0015,
                            lng: this.props.commonStore.statsResults[spc_int]['geo']['lng']+Math.random()*0.003
                        }}
                        icon={{
                            url:`https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/scores/${(this.props.commonStore.statsResults[spc_int]['review']['flags'].totalScore/this.props.commonStore.statsResults[spc_int]['review']['flags'].count).toFixed(1)}.png`,
                            scaledSize: new google.maps.Size(37.5,37.5)
                        }}

                        onClick = {this.onReviewClick( spc_int)}
                        clickable
                    /> : null ): null)
                ) ) }

            </Map>
        );
    }
}

export default inject('commonStore', 'routerStore', 'seoStore')( observer(GoogleApiWrapper({
    apiKey: 'AIzaSyDe02X3uKLDDuZV7xf9i1V_PQ-XzoFiq8o', libraries:['places']
})(MapContainerSearch)))