import React from 'react';
import {inject, observer} from "mobx-react";
import Chip from '@mui/material/Chip';


function ExploreFactors(props) {

    const {fullFactors, selectedFactors, statsResults} = props.commonStore;
    const {location} = props.routerStore;

    const displayFactors = ["Criterion_TravelTime", "Criterion_Safety_Combined",  "Criterion_Unemployment_Rate", "Criterion_Age_Group_16_25", "Criterion_Restaurants_Density", "Criterion_Population_Density_High_Preferred" ];

    const selectedFactorsKeys = selectedFactors.reduce(function(a,b,i){
        a= [...a, b['key']]
        return a;
    }, [])


    const factorMap =  fullFactors.reduce(function (a, b, i) {
        a[b['key']]  = b['label'];
        return a;
    }, {});


    const handleClick = (factor) =>() => {
        (async () => {
            let newSelectedFactors = []
            if (selectedFactorsKeys.includes(factor)) {
                newSelectedFactors = selectedFactors.filter(x => x.key !== factor)
            } else {
                newSelectedFactors = [...selectedFactors, ...fullFactors.filter(x => x.key === factor)]
            }
            props.commonStore.changeState('selectedFactors', newSelectedFactors)
            props.commonStore.reCalFactors('score')

        })()
    };


    return (
        <div className="">
            {displayFactors.map((factor, i)=>(
                    <Chip key={i} label={factorMap[factor]}
                          color={(selectedFactorsKeys.includes(factor)? "primary": "default")}
                          style={{
                              backgroundColor:(selectedFactorsKeys.includes(factor)? "#2196f3": "white"),
                              margin: "5px",
                              fontWeight: "bold",
                              border: "1px solid #2196f3"
                          }}   
                          onClick={handleClick(factor)} />
            ))}
        </div>
    )

}


export default inject('commonStore', 'routerStore') (observer(ExploreFactors));




