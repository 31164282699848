import * as React from 'react';
import {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Edit from '@material-ui/icons/Edit';
import Undo from '@material-ui/icons/Undo';
import Typography from '@mui/material/Typography';
import {red} from '@mui/material/colors';
import {useCookies} from "react-cookie";


function Comment(props) {

    const {displayOptions, selectedSPCINT, ratings, comments } = props.commonStore;
    const [cookies, setCookie] = useCookies();

    const [values, setValues] = React.useState({
        Comments : []
    });


    useEffect(() => {
        (async () => {
            let data = {};
            if (comments[selectedSPCINT]===undefined){
                await fetch(`https://utvyq4kagd.execute-api.eu-west-1.amazonaws.com/default/spcComments?spcint=${selectedSPCINT}`, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key':'KJT52ZMGF14N1kvCfZxAG6wWpmOqxHku1ILOAjxc'
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => response.json())
                    .then(function(data){
                        let commentSorted = Object.keys(data).reduce(function(a,b){
                            a.push(data[b]);
                            return a;
                        },[]);

                        commentSorted.sort(function(a, b) {
                            return a['SubDate']< b['SubDate'];
                        });

                        console.log(commentSorted)

                        setValues({...values, Comments: commentSorted} )
                        props.commonStore.changeState('comments', {...comments, [selectedSPCINT]: commentSorted})
                    })
                    .catch(function(error){
                        console.log(error)
                    });
            }
        })()
    }, []);

    const handleClick = () => ()=>{

        props.commonStore.changeStateDic(!displayOptions.reviewOpen, 'reviewOpen', 'displayOptions')

    };

    return (
        <div>
            {comments[selectedSPCINT]? comments[selectedSPCINT].map((comment,i)=>(<div style={{margin:'5px'}} key={i}><Card sx={{ maxWidth: 345 }} >
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {comment["UserID"].substr(0,1).toUpperCase()}
                        </Avatar>
                    }

                    title={`${comment["UserID"].substr(0,1).toUpperCase()}***${comment["UserID"].substr(-1,1).toLowerCase()}`}
                    subheader={comment["SubDate"]}
                />

                <CardContent>
                    <div style={{display:'flex', flexDirection:'row', paddingRight:'10px'}}>
                    {[1,2,3,4,5].map((j)=>(
                        <div key={j}>
                            {(j <= comment['Rating']?
                                <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star</span>:
                                (j - comment['Rating'] < 1?
                                    <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star_half</span> :
                                    <span className="material-icons" style={{color:'orange', fontSize:'12px'}}>star_outline</span>))}
                        </div>))}
                    </div>


                    <Typography variant="body2" color="text.secondary">
                        {comment["Comment"]}
                    </Typography>
                    {cookies[comment['SubID']]!==undefined? <div style={{display:'flex', flexDirection: 'row',  justifyContent :'flex-end', width:'100%'}}>
                        <IconButton aria-label="Edit" onClick={handleClick()}>
                            {(displayOptions.backendRunning?<img
                                src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                                alt="thank-you"
                                style={{display: 'block', margin: 'auto', width: '20px'}}
                            /> :(displayOptions.reviewOpen? <Undo/>: <Edit/>))}

                        </IconButton>
                    </div>: null}

                </CardContent>

            </Card></div>)): null}

        </div>
    );
}


export default inject('commonStore', 'routerStore') (observer(Comment));