import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import ExploreResult from "./ExploreResult";
import ExploreResultMobile from "./ExploreResultMobile";
import ExploreBox from "./ExploreBox";
import ExploreBoxMobile from "./ExploreBoxMobile";
import ExploreFactors from "./ExploreFactors";
import MapContainerExplore from "../map/MapContainerExplore";
import AreaCard from "../area-card/AreaCard";
import {Search} from '@material-ui/icons';
import Collapse from '@mui/material/Collapse';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import "./exploreresultcontainer.css";
import Notification from "../Notification";

function ExploreResultContainerMobile(props) {

    const {displayOptions, defaultCenter, polygonPoints} = props.commonStore;


    const [values, setValues] = React.useState({
        checked: false,
    });


    useEffect(() => {
        (async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setValues({...values, checked: true})

        })()
    }, []);

    const onSearch = ()=> ()=>{
        props.commonStore.changeStateDic(!displayOptions.sideOpen2, 'sideOpen2', 'displayOptions')
    }



    return (
        <div className="explore-result-root-mobile">


            {displayOptions.backendRunning?<img
                    src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                    alt="thank-you"
                    style={{display: 'block', margin: 'auto', width: '20px',}}
                /> :

                <Collapse orientation="horizontal" in={displayOptions.sideOpen2}>
                    < div className="explore-result-searchbox-mobile"><ExploreBoxMobile/></div>
                    <div className="explore-result-factors-mobile"><ExploreFactors/> </div>

                </Collapse>}

            <Zoom in={!displayOptions.sideOpen2} style={{ transitionDelay: !displayOptions.sideOpen2 ? '300ms' : '0ms' }}>
                <Fab color="success" aria-label="search"  size="small"
                     style={{position:'fixed', zIndex:3}} onClick ={onSearch()}
                     disabled = {displayOptions.backendRunning}
                >
                    {displayOptions.backendRunning?<img
                        src={'https://smartpostcodeimage.s3-eu-west-1.amazonaws.com/website/loading_spin.gif'}
                        alt="thank-you"
                        style={{display: 'block', margin: 'auto', width: '20px',}}
                    /> :  <Search />}
                </Fab>
            </Zoom>

            <div className="explore-result-resultlist-mobile"> <ExploreResultMobile/></div>
            {(displayOptions.areaCardOn ? <div className="explore-result-area-mobile"><AreaCard/></div>: null)}


                <div className="explore-result-map-mobile">
                    <MapContainerExplore center={defaultCenter} points={polygonPoints}/>
                </div>



            <Notification/>


        </div>

    )

}


export default inject('commonStore', 'routerStore') (observer(ExploreResultContainerMobile));


/*



      */



