import {Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import React from "react";
import "./tagbox.css";


function TagBox(props) {


    return (
        <div className="tagbox-root">
            <Typography variant="caption">   Popular Tags: </Typography>
            { props.data.slice(0,5).map((tag, i)=>( <div key={i} className="tagbox-tag"><Typography variant="caption">{tag}</Typography></div>))}
        </div>
    )


}

export default inject('commonStore', 'routerStore') (observer(TagBox));