import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import LandingBlog from './Landing_Blog';
import AppParams from "../../functions/AppParams";
import LandingApp from './Landing_App';
import Grid from '@mui/material/Grid';
import './landing.css';


function Landing(props) {

    const {blogIndex} = props.commonStore;

    useEffect(() => {
        (async () => {
            if(blogIndex.length===0) {
                let appSetting = await AppParams();
                props.commonStore.changeState('blogIndex', appSetting.blogList);
            }
        })()
    }, []);



    return (
        <div className="landing-root" >
            <div className="landing-main">
                    <Grid container spacing={{ xs: 2, md: 3 }} >
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <LandingApp/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <LandingBlog/>
                        </Grid>
                    </Grid>
            </div>
        </div>
    );
}

export default inject('commonStore', 'routerStore') (observer(Landing));