import React, {Component} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {Grid, IconButton, Input, InputAdornment, MenuItem} from '@material-ui/core';
import {Cancel, Search} from '@material-ui/icons';
import {inject, observer} from "mobx-react";


const searchOptions = {
    componentRestrictions: {
        country: 'gb'
    },
    types: []
};

class LandingAppSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '' ,
            message: '',
        };
    }

    handleChange = address => {
        this.setState({ address });
        this.props.commonStore.changeState('tempAddress', address);

    };

    onCancel = ()=>()=>{
        this.setState({address: ''});
        this.props.commonStore.changeState('tempAddress', '')
    };


    onSearch = ()=> ()=>{
        let factorString = this.props.commonStore.statsSelectedFactors.reduce(function(a, b, i){
            a = [...a, b['label'].toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_')]
            return a;
        }, []).join()

        if(this.props.commonStore.searchGeo.lat===0 || this.props.commonStore.searchGeo.lng===0 ){
            this.props.commonStore.changeState('alertOn', true);
        }else {
            this.props.commonStore.changeState([{lat: this.props.commonStore.searchGeo.lat, lng: this.props.commonStore.searchGeo.lng}, ...this.props.commonStore.queryMarkers], 'queryMarkers')
            window.open(`/searchstats?lat=${this.props.commonStore.searchGeo.lat}&lng=${this.props.commonStore.searchGeo.lng}&factors=${factorString}`, '_self')
        }
    };

    handleSelect = address => {
        this.setState({ address });
        this.props.commonStore.changeState('tempAddress', address);
        let factorString = this.props.commonStore.statsSelectedFactors.reduce(function(a, b, i){
            a = [...a, b['label'].toLowerCase().replace(/\s/g, '_').replace('-','_').replace('___','_').replace('__','_')]
            return a;
        }, []).join()

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng =>
                {
                    this.props.commonStore.changeState('searchGeo', {lat:latLng.lat, lng: latLng.lng  } );
                    this.props.commonStore.changeState('searchText', address);
                    this.props.commonStore.changeState([{lat: this.props.commonStore.searchGeo.lat, lng: this.props.commonStore.searchGeo.lng}, ...this.props.commonStore.queryMarkers], 'queryMarkers')
                    window.open(`/searchstats?lat=${this.props.commonStore.searchGeo.lat}&lng=${this.props.commonStore.searchGeo.lng}&factors=${factorString}`, '_self')
                }
            )
            .catch(error => {
                console.error(error);

            });
    };



    onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    render(){
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onError={this.onError}
                onSelect={this.handleSelect}
                searchOptions = {searchOptions}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Input
                                {...getInputProps({
                                    placeholder: 'England Only ...',
                                })}
                                disableUnderline={true}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton aria-label="delete"  onClick ={this.onSearch()}>
                                            <Search fontSize="inherit" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <IconButton aria-label="delete"  onClick ={this.onCancel()}>
                                <Cancel fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <div
                            className="autocomplete-dropdown-container"
                            style={{position:'absolute',
                                zIndex:100,
                                display: 'flex',
                                flexDirection:'column',
                                backgroundColor:'white',
                                margin:'5px',

                            }}
                        >
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <MenuItem
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </MenuItem>
                                );
                            })}
                        </div>

                    </div>
                )}
            </PlacesAutocomplete>
        )
    }
}

export default inject('commonStore', 'routerStore') (observer(LandingAppSearch));

/*



 */